@font-face {
  font-family: 'entypo';
  src: url('../fonts/entypo.eot?49384070');
  src: url('../fonts/entypo.eot?49384070#iefix') format('embedded-opentype'),
       url('../fonts/entypo.woff?49384070') format('woff'),
       url('../fonts/entypo.ttf?49384070') format('truetype'),
       url('../fonts/entypo.svg?49384070#entypo') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'entypo';
    src: url('../fonts/entypo.svg?49384070#entypo') format('svg');
  }
}

 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "entypo";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
     
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-note:before { content: '\e800'; } /* '' */
.icon-logo-db:before { content: '\e978'; } /* '' */
.icon-music:before { content: '\e802'; } /* '' */
.icon-search:before { content: '\e803'; } /* '' */
.icon-flashlight:before { content: '\e804'; } /* '' */
.icon-mail:before { content: '\e805'; } /* '' */
.icon-heart:before { content: '\e806'; } /* '' */
.icon-heart-empty:before { content: '\e807'; } /* '' */
.icon-star:before { content: '\e808'; } /* '' */
.icon-star-empty:before { content: '\e809'; } /* '' */
.icon-user:before { content: '\e80a'; } /* '' */
.icon-users:before { content: '\e80b'; } /* '' */
.icon-user-add:before { content: '\e80c'; } /* '' */
.icon-video:before { content: '\e80d'; } /* '' */
.icon-picture:before { content: '\e80e'; } /* '' */
.icon-camera:before { content: '\e80f'; } /* '' */
.icon-layout:before { content: '\e810'; } /* '' */
.icon-menu:before { content: '\e811'; } /* '' */
.icon-check:before { content: '\e812'; } /* '' */
.icon-cancel-1:before { content: '\e813'; } /* '' */
.icon-cancel-circled:before { content: '\e814'; } /* '' */
.icon-cancel-squared:before { content: '\e815'; } /* '' */
.icon-plus:before { content: '\e816'; } /* '' */
.icon-plus-circled:before { content: '\e817'; } /* '' */
.icon-plus-squared:before { content: '\e818'; } /* '' */
.icon-minus:before { content: '\e819'; } /* '' */
.icon-minus-circled:before { content: '\e81a'; } /* '' */
.icon-minus-squared:before { content: '\e81b'; } /* '' */
.icon-help:before { content: '\e81c'; } /* '' */
.icon-help-circled-1:before { content: '\e81d'; } /* '' */
.icon-info:before { content: '\e81e'; } /* '' */
.icon-info-circled:before { content: '\e81f'; } /* '' */
.icon-back:before { content: '\e820'; } /* '' */
.icon-home:before { content: '\e821'; } /* '' */
.icon-link:before { content: '\e822'; } /* '' */
.icon-attach:before { content: '\e823'; } /* '' */
.icon-lock-1:before { content: '\e824'; } /* '' */
.icon-lock-open-1:before { content: '\e825'; } /* '' */
.icon-eye-1:before { content: '\e826'; } /* '' */
.icon-tag:before { content: '\e827'; } /* '' */
.icon-bookmark:before { content: '\e828'; } /* '' */
.icon-bookmarks:before { content: '\e829'; } /* '' */
.icon-flag:before { content: '\e82a'; } /* '' */
.icon-thumbs-up:before { content: '\e82b'; } /* '' */
.icon-thumbs-down:before { content: '\e82c'; } /* '' */
.icon-download:before { content: '\e82d'; } /* '' */
.icon-upload:before { content: '\e82e'; } /* '' */
.icon-upload-cloud:before { content: '\e82f'; } /* '' */
.icon-reply-1:before { content: '\e830'; } /* '' */
.icon-reply-all:before { content: '\e831'; } /* '' */
.icon-forward:before { content: '\e832'; } /* '' */
.icon-quote:before { content: '\e833'; } /* '' */
.icon-code:before { content: '\e834'; } /* '' */
.icon-export-1:before { content: '\e835'; } /* '' */
.icon-pencil-1:before { content: '\e836'; } /* '' */
.icon-feather:before { content: '\e837'; } /* '' */
.icon-print:before { content: '\e838'; } /* '' */
.icon-retweet:before { content: '\e839'; } /* '' */
.icon-keyboard:before { content: '\e83a'; } /* '' */
.icon-comment-1:before { content: '\e83b'; } /* '' */
.icon-chat-1:before { content: '\e83c'; } /* '' */
.icon-bell:before { content: '\e83d'; } /* '' */
.icon-attention:before { content: '\e83e'; } /* '' */
.icon-alert:before { content: '\e83f'; } /* '' */
.icon-vcard:before { content: '\e840'; } /* '' */
.icon-address:before { content: '\e841'; } /* '' */
.icon-location-1:before { content: '\e842'; } /* '' */
.icon-map:before { content: '\e843'; } /* '' */
.icon-direction:before { content: '\e844'; } /* '' */
.icon-compass:before { content: '\e845'; } /* '' */
.icon-cup:before { content: '\e846'; } /* '' */
.icon-trash:before { content: '\e847'; } /* '' */
.icon-doc:before { content: '\e848'; } /* '' */
.icon-docs:before { content: '\e849'; } /* '' */
.icon-doc-landscape:before { content: '\e84a'; } /* '' */
.icon-doc-text:before { content: '\e84b'; } /* '' */
.icon-doc-text-inv:before { content: '\e84c'; } /* '' */
.icon-newspaper:before { content: '\e84d'; } /* '' */
.icon-book-open:before { content: '\e84e'; } /* '' */
.icon-book:before { content: '\e84f'; } /* '' */
.icon-folder-1:before { content: '\e850'; } /* '' */
.icon-archive:before { content: '\e851'; } /* '' */
.icon-box:before { content: '\e852'; } /* '' */
.icon-rss:before { content: '\e853'; } /* '' */
.icon-phone:before { content: '\e854'; } /* '' */
.icon-cog:before { content: '\e855'; } /* '' */
.icon-tools:before { content: '\e856'; } /* '' */
.icon-share:before { content: '\e857'; } /* '' */
.icon-shareable:before { content: '\e858'; } /* '' */
.icon-basket:before { content: '\e859'; } /* '' */
.icon-bag:before { content: '\e85a'; } /* '' */
.icon-calendar:before { content: '\e85b'; } /* '' */
.icon-login:before { content: '\e85c'; } /* '' */
.icon-logout-1:before { content: '\e85d'; } /* '' */
.icon-mic-1:before { content: '\e85e'; } /* '' */
.icon-mute:before { content: '\e85f'; } /* '' */
.icon-sound:before { content: '\e860'; } /* '' */
.icon-volume:before { content: '\e861'; } /* '' */
.icon-clock-1:before { content: '\e862'; } /* '' */
.icon-hourglass:before { content: '\e863'; } /* '' */
.icon-lamp:before { content: '\e864'; } /* '' */
.icon-light-down:before { content: '\e865'; } /* '' */
.icon-light-up:before { content: '\e866'; } /* '' */
.icon-adjust:before { content: '\e867'; } /* '' */
.icon-block:before { content: '\e868'; } /* '' */
.icon-resize-full:before { content: '\e869'; } /* '' */
.icon-resize-small:before { content: '\e86a'; } /* '' */
.icon-popup:before { content: '\e86b'; } /* '' */
.icon-publish:before { content: '\e86c'; } /* '' */
.icon-window:before { content: '\e86d'; } /* '' */
.icon-arrow-combo:before { content: '\e86e'; } /* '' */
.icon-down-circled:before { content: '\e86f'; } /* '' */
.icon-left-circled:before { content: '\e870'; } /* '' */
.icon-right-circled:before { content: '\e871'; } /* '' */
.icon-up-circled:before { content: '\e872'; } /* '' */
.icon-down-open:before { content: '\e873'; } /* '' */
.icon-left-open-1:before { content: '\e874'; } /* '' */
.icon-right-open-1:before { content: '\e875'; } /* '' */
.icon-up-open:before { content: '\e876'; } /* '' */
.icon-down-open-mini:before { content: '\e877'; } /* '' */
.icon-left-open-mini:before { content: '\e878'; } /* '' */
.icon-right-open-mini:before { content: '\e879'; } /* '' */
.icon-up-open-mini:before { content: '\e87a'; } /* '' */
.icon-down-open-big:before { content: '\e88c'; } /* '' */
.icon-left-open-big:before { content: '\e8a0'; } /* '' */
.icon-right-open-big:before { content: '\e8b3'; } /* '' */
.icon-up-open-big:before { content: '\e8b4'; } /* '' */
.icon-down:before { content: '\e8c7'; } /* '' */
.icon-left-1:before { content: '\e8c8'; } /* '' */
.icon-right-1:before { content: '\e8db'; } /* '' */
.icon-up:before { content: '\e8dc'; } /* '' */
.icon-down-dir:before { content: '\e8e0'; } /* '' */
.icon-left-dir:before { content: '\e8e1'; } /* '' */
.icon-right-dir:before { content: '\e8e2'; } /* '' */
.icon-up-dir:before { content: '\e8e3'; } /* '' */
.icon-down-bold:before { content: '\e8e4'; } /* '' */
.icon-left-bold:before { content: '\e8e5'; } /* '' */
.icon-right-bold:before { content: '\e8e6'; } /* '' */
.icon-up-bold-1:before { content: '\e8e7'; } /* '' */
.icon-down-thin:before { content: '\e8e8'; } /* '' */
.icon-left-thin:before { content: '\e8e9'; } /* '' */
.icon-right-thin:before { content: '\e8ea'; } /* '' */
.icon-note-beamed:before { content: '\e801'; } /* '' */
.icon-ccw:before { content: '\e8ec'; } /* '' */
.icon-cw:before { content: '\e8ed'; } /* '' */
.icon-arrows-ccw:before { content: '\e8ee'; } /* '' */
.icon-level-down:before { content: '\e8ef'; } /* '' */
.icon-level-up:before { content: '\e8f0'; } /* '' */
.icon-shuffle:before { content: '\e8f1'; } /* '' */
.icon-loop:before { content: '\e8f2'; } /* '' */
.icon-switch:before { content: '\e8f3'; } /* '' */
.icon-play:before { content: '\e8f4'; } /* '' */
.icon-stop:before { content: '\e8f5'; } /* '' */
.icon-pause:before { content: '\e8f6'; } /* '' */
.icon-record:before { content: '\e8f7'; } /* '' */
.icon-to-end:before { content: '\e8f8'; } /* '' */
.icon-to-start:before { content: '\e8f9'; } /* '' */
.icon-fast-forward-1:before { content: '\e8fa'; } /* '' */
.icon-fast-backward:before { content: '\e8fb'; } /* '' */
.icon-progress-0:before { content: '\e8fc'; } /* '' */
.icon-progress-1:before { content: '\e8fd'; } /* '' */
.icon-progress-2:before { content: '\e8fe'; } /* '' */
.icon-progress-3:before { content: '\e8ff'; } /* '' */
.icon-target:before { content: '\e900'; } /* '' */
.icon-palette:before { content: '\e901'; } /* '' */
.icon-list:before { content: '\e902'; } /* '' */
.icon-list-add:before { content: '\e903'; } /* '' */
.icon-signal:before { content: '\e904'; } /* '' */
.icon-trophy-1:before { content: '\e905'; } /* '' */
.icon-battery:before { content: '\e906'; } /* '' */
.icon-back-in-time:before { content: '\e907'; } /* '' */
.icon-monitor-1:before { content: '\e908'; } /* '' */
.icon-mobile:before { content: '\e909'; } /* '' */
.icon-network:before { content: '\e90a'; } /* '' */
.icon-cd:before { content: '\e90b'; } /* '' */
.icon-inbox-1:before { content: '\e90c'; } /* '' */
.icon-install:before { content: '\e90d'; } /* '' */
.icon-globe:before { content: '\e90e'; } /* '' */
.icon-cloud-1:before { content: '\e90f'; } /* '' */
.icon-cloud-thunder:before { content: '\e910'; } /* '' */
.icon-flash:before { content: '\e911'; } /* '' */
.icon-moon:before { content: '\e912'; } /* '' */
.icon-flight:before { content: '\e913'; } /* '' */
.icon-paper-plane:before { content: '\e914'; } /* '' */
.icon-leaf:before { content: '\e915'; } /* '' */
.icon-lifebuoy:before { content: '\e916'; } /* '' */
.icon-mouse:before { content: '\e917'; } /* '' */
.icon-briefcase:before { content: '\e918'; } /* '' */
.icon-suitcase:before { content: '\e919'; } /* '' */
.icon-dot:before { content: '\e91a'; } /* '' */
.icon-dot-2:before { content: '\e91b'; } /* '' */
.icon-dot-3:before { content: '\e91c'; } /* '' */
.icon-brush:before { content: '\e91d'; } /* '' */
.icon-magnet:before { content: '\e91e'; } /* '' */
.icon-infinity:before { content: '\e91f'; } /* '' */
.icon-erase:before { content: '\e920'; } /* '' */
.icon-chart-pie:before { content: '\e921'; } /* '' */
.icon-chart-line:before { content: '\e922'; } /* '' */
.icon-chart-bar:before { content: '\e923'; } /* '' */
.icon-chart-area:before { content: '\e924'; } /* '' */
.icon-tape:before { content: '\e925'; } /* '' */
.icon-graduation-cap:before { content: '\e926'; } /* '' */
.icon-language:before { content: '\e927'; } /* '' */
.icon-ticket:before { content: '\e928'; } /* '' */
.icon-water:before { content: '\e929'; } /* '' */
.icon-droplet:before { content: '\e92a'; } /* '' */
.icon-air:before { content: '\e92b'; } /* '' */
.icon-credit-card:before { content: '\e92c'; } /* '' */
.icon-floppy:before { content: '\e92d'; } /* '' */
.icon-clipboard:before { content: '\e92e'; } /* '' */
.icon-megaphone:before { content: '\e92f'; } /* '' */
.icon-database:before { content: '\e930'; } /* '' */
.icon-drive:before { content: '\e931'; } /* '' */
.icon-bucket:before { content: '\e932'; } /* '' */
.icon-thermometer:before { content: '\e933'; } /* '' */
.icon-key:before { content: '\e934'; } /* '' */
.icon-flow-cascade:before { content: '\e935'; } /* '' */
.icon-flow-branch:before { content: '\e936'; } /* '' */
.icon-flow-tree:before { content: '\e937'; } /* '' */
.icon-flow-line:before { content: '\e938'; } /* '' */
.icon-flow-parallel:before { content: '\e939'; } /* '' */
.icon-rocket:before { content: '\e93a'; } /* '' */
.icon-gauge:before { content: '\e93b'; } /* '' */
.icon-traffic-cone:before { content: '\e93c'; } /* '' */
.icon-cc:before { content: '\e93d'; } /* '' */
.icon-cc-by:before { content: '\e93e'; } /* '' */
.icon-cc-nc:before { content: '\e93f'; } /* '' */
.icon-cc-nc-eu:before { content: '\e940'; } /* '' */
.icon-cc-nc-jp:before { content: '\e941'; } /* '' */
.icon-cc-sa:before { content: '\e942'; } /* '' */
.icon-cc-nd:before { content: '\e943'; } /* '' */
.icon-cc-pd:before { content: '\e944'; } /* '' */
.icon-cc-zero:before { content: '\e945'; } /* '' */
.icon-cc-share:before { content: '\e946'; } /* '' */
.icon-cc-remix:before { content: '\e947'; } /* '' */
.icon-github:before { content: '\e948'; } /* '' */
.icon-github-circled:before { content: '\e949'; } /* '' */
.icon-flickr:before { content: '\e94a'; } /* '' */
.icon-flickr-circled:before { content: '\e94b'; } /* '' */
.icon-vimeo:before { content: '\e94c'; } /* '' */
.icon-vimeo-circled:before { content: '\e94d'; } /* '' */
.icon-twitter:before { content: '\e94e'; } /* '' */
.icon-twitter-circled:before { content: '\e94f'; } /* '' */
.icon-facebook:before { content: '\e950'; } /* '' */
.icon-facebook-circled:before { content: '\e951'; } /* '' */
.icon-facebook-squared:before { content: '\e952'; } /* '' */
.icon-gplus:before { content: '\e953'; } /* '' */
.icon-gplus-circled:before { content: '\e954'; } /* '' */
.icon-pinterest:before { content: '\e955'; } /* '' */
.icon-pinterest-circled:before { content: '\e956'; } /* '' */
.icon-tumblr:before { content: '\e957'; } /* '' */
.icon-tumblr-circled:before { content: '\e958'; } /* '' */
.icon-linkedin:before { content: '\e959'; } /* '' */
.icon-linkedin-circled:before { content: '\e95a'; } /* '' */
.icon-dribbble:before { content: '\e95b'; } /* '' */
.icon-dribbble-circled:before { content: '\e95c'; } /* '' */
.icon-stumbleupon:before { content: '\e95d'; } /* '' */
.icon-stumbleupon-circled:before { content: '\e95e'; } /* '' */
.icon-lastfm:before { content: '\e95f'; } /* '' */
.icon-lastfm-circled:before { content: '\e960'; } /* '' */
.icon-rdio:before { content: '\e961'; } /* '' */
.icon-rdio-circled:before { content: '\e962'; } /* '' */
.icon-spotify:before { content: '\e963'; } /* '' */
.icon-spotify-circled:before { content: '\e964'; } /* '' */
.icon-qq:before { content: '\e965'; } /* '' */
.icon-instagram:before { content: '\e966'; } /* '' */
.icon-dropbox:before { content: '\e967'; } /* '' */
.icon-evernote:before { content: '\e968'; } /* '' */
.icon-flattr:before { content: '\e969'; } /* '' */
.icon-skype:before { content: '\e96a'; } /* '' */
.icon-skype-circled:before { content: '\e96b'; } /* '' */
.icon-renren:before { content: '\e96c'; } /* '' */
.icon-sina-weibo:before { content: '\e96d'; } /* '' */
.icon-paypal:before { content: '\e96e'; } /* '' */
.icon-picasa:before { content: '\e96f'; } /* '' */
.icon-soundcloud:before { content: '\e970'; } /* '' */
.icon-mixi:before { content: '\e971'; } /* '' */
.icon-behance:before { content: '\e972'; } /* '' */
.icon-google-circles:before { content: '\e973'; } /* '' */
.icon-vkontakte:before { content: '\e974'; } /* '' */
.icon-smashing:before { content: '\e975'; } /* '' */
.icon-sweden:before { content: '\e976'; } /* '' */
.icon-db-shape:before { content: '\e977'; } /* '' */
.icon-up-thin:before { content: '\e8eb'; } /* '' */