@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url(https://themes.googleusercontent.com/static/fonts/lato/v7/KT3KS9Aol4WfR6Vas8kNcg.woff) format('woff');
}
@font-face {
  	font-family: 'Lato';
  	font-style: normal;
  	font-weight: 400;
  	src: local('Lato Regular'), local('Lato-Regular'), url(https://themes.googleusercontent.com/static/fonts/lato/v7/9k-RPmcnxYEPm8CNFsH2gg.woff) format('woff');
}
@font-face {
  	font-family: 'Lato';
  	font-style: normal;
  	font-weight: 700;
  	src: local('Lato Bold'), local('Lato-Bold'), url(https://themes.googleusercontent.com/static/fonts/lato/v7/wkfQbvfT_02e2IWO3yYueQ.woff) format('woff');
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Light.svg') format('svg');
    font-weight: 300;
  }
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato.svg') format('svg');
    font-weight: 400;
  }
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Bold.svg') format('svg');
    font-weight: 700;
  }
}
