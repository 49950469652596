.text-grey {
    color: #9b9b9b !important;
}

.m-l-sm {
    margin-left: 10px;
}

.m-t-lg {
    margin-top: 20px !important;
}

body {
    min-height: 100%;
    background-color: ivory; !important;
}

.trackNumber {
    max-width: 80px !important;
    width: 80px !important;
}

.btn-download {
    display: block;
    float: left;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 13px;
    color: #fff;
    line-height: 14px;
    padding: 13px 50px;
    text-transform: uppercase;
    background: #ec3838;
    margin-top: 30px;
    text-decoration: none;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.dataTables_filter {
    float: right;
}

.dataTables_paginate {
    float: right;
}

.dataTables_filter label input {
    margin-left: 10px;
}

.dataTables_length select {
    height: auto !important;
}

.dataTables_processing {
    position: absolute;
    top: 20%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
    background-color: #FFFFFF;
    color: #000000;
    border: 2px solid #ed3939;
    border-radius: 2px;
    -moz-border-radius: 2px;
}

.closify-container {
    border: 3px solid #fff;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
}

.text-strong {
    font-weight: bold !important;
}

.ui-sortable-handle td {
    cursor: move !important;
}

.sortable-placeholder {
    background-color: #9b9b9b !important;
    border: 3px dashed #FFFFFF;
}
.sortable-placeholder td {
    background-color: #9b9b9b !important;
    border: 3px dashed #FFFFFF;
}

.bootstrap-datetimepicker-widget {
    display: block !important;
    color: #000;
}

.date .input-group-addon {
    color: #FFFFFF;
}

.cursor-hand {
    cursor: pointer !important;
}

.popover {
    color: #000000 !important;
}

.bootbox {
    margin-top: 200px !important;
}

.bootbox .modal-body, .modal-footer {
    background-color: #464646 !important;
    color: #FFFFFF;
}

.bootbox .btn-primary, .btn-primary:active, .btn-primary:hover, .btn-primary:focus {
    background-color: #ed3939 !important;
    border: 0px !important;
}

.ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #EC3838;
    background: linear-gradient(#EC3838 0%, #EC3838 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
}

.ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #EC3838;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #EC3838;
}

.ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #EC3838;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #EC3838;
}

.ribbon-left span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #79A70A;
    background: linear-gradient(#EC3838 0%, #EC3838 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    left: -21px;
}

.ribbon-left span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #EC3838;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #EC3838;
}

.ribbon-left span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #EC3838;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #EC3838;
}

.cover-image-max {
 /*   min-width: 250px !important;
    min-height: 250px !important;
    max-width: 250px !important;
    max-height: 250px !important;*/
    width: 100%;
}

@media screen and (max-width: 991px) {
    .cover-image-max {
        min-width: 230px !important;
        min-height: 230px !important;
        max-width: 230px !important;
        max-height: 230px !important;
    }
}

@media screen and (max-width: 762px) {
    .cover-image-max {
        min-width: 100% !important;
        max-width: 100% !important;
        max-height: 100% !important;
        height: auto !important;
    }
}

.cover-image-max-sm {
    /*min-width: 180px !important;*/
    min-height: 180px !important;
    max-width: 180px !important;
    max-height: 180px !important;
    width: 100%;
}

.no-min {
    min-width: auto !important;
    min-height: auto !important;
}

.folded-header {
    position: relative;
    margin-left: -50%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    padding: 0px 40% 0px 50%;
    background-color: #525252;
    width: 188%;
}

.folded-header i {
    position: relative;
    top: 5px;
    margin-left: -10px;
    margin-right: 10px;
}


.shadow {
    overflow: hidden;
}

.shadow:after {
    box-shadow: -1px 1px 5px #212835;
}

.border-radius {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.border-radius:after {
    border-radius: 0 0 0 10px;
}

.slider-item {
    position: relative;
}

.slider-title {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    line-height: 10px;
    color: #fff;
    padding: 5px 10px 5px 7px;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 5px;
    bottom: 25px;
    width: 170px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.countdown-modal .modal-body, .modal-footer {
    background-color: #181818 !important;
}

.countdown-modal .modal-footer {
    border-top: 0px !important;
}

.album {
    border: 2px solid transparent;
}

.highlighted-mixtape {
    border: 2px dashed gold !important;
}

.highlighted-mixtape-red {
    border: 2px dashed #EC3838 !important;
}

.no-padding {
    padding: 0px !important;
}

.btn-radio {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 325px;
}
.jp-playlist-item {
    white-space: nowrap;
    overflow: hidden;
}
#mixtapes-table td, #mixtapes-table th {
    text-align: center;
}
#mixtapes-table td input{
    cursor: pointer;
}