@import "../css/fonts.css";
@import "../css/entypo.css";
@import "../css/flaticon.css";
@import "../css/lightbox.css";

.progress-bar-filled {
    -webkit-animation: progressBar 1.3s;
    animation: progressBar 1.3s;
}

@-webkit-keyframes progressBar {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@keyframes progressBar {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.scale-In {
    -webkit-animation: scaleIn 1s;
    animation: scaleIn 1s;
}

@-webkit-keyframes scaleIn {
    0% {
        -webkit-transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
    }
}

@keyframes scaleIn {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

.scale-InOut {
    -webkit-animation: scaleInOut 1s;
    animation: scaleIn 1s;
}

@-webkit-keyframes scaleInOut {
    50% {
        -webkit-transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
    }
}

@keyframes scaleInOut {
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.rotate-LR {
    -webkit-animation: rotateLR 1s;
    animation: rotateLR 1s;
}

@-webkit-keyframes rotateLR {
    25% {
        -webkit-transform: rotate(45deg);
    }
    75% {
        -webkit-transform: rotate(-45deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
    }
}

@keyframes rotateLR {
    25% {
        transform: rotate(45deg);
    }
    75% {
        transform: rotate(-45deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.rotate-In {
    -webkit-animation: rotateIn 1s;
    animation: rotateIn 1s;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
}

@-webkit-keyframes rotateIn {
    0% {
        -webkit-transform: rotateY(-90deg);
    }
    100% {
        -webkit-transform: rotateY(0deg);
    }
}

@keyframes rotateIn {
    0% {
        transform: rotateY(-90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

.rotate-InX {
    -webkit-animation: rotateInX 1s;
    animation: rotateInX 1s;
}

@-webkit-keyframes rotateInX {
    0% {
        -webkit-transform: rotate(-15deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
    }
}

@keyframes rotateInX {
    0% {
        transform: rotate(-15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.fadeIn {
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeOut {
    -webkit-animation: fadeOut 1s;
    animation: fadeOut 1s;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fadeUp {
    -webkit-animation: fadeUp 1s;
    animation: fadeUp 1s;
}

@-webkit-keyframes fadeUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeDown {
    -webkit-animation: fadeDown .6s;
    animation: fadeDown .6s;
}

.fadeDown.delay-2 {
    -webkit-animation: fadeDown .9s;
    animation: fadeDown .9s;
}

.fadeDown.delay-3 {
    -webkit-animation: fadeDown 1.2s;
    animation: fadeDown 1.2s;
}

.fadeDown.delay-4 {
    -webkit-animation: fadeDown 1.5s;
    animation: fadeDown 1.5s;
}

@-webkit-keyframes fadeDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeDown {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeL {
    -webkit-animation: fadeL 1s;
    animation: fadeL 1s;
}

@-webkit-keyframes fadeL {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeL {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeR {
    -webkit-animation: fadeR 1s;
    animation: fadeR 1s;
}

@-webkit-keyframes fadeR {
    0% {
        opacity: 0;
        -webkit-transform: translateX(50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeR {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/*=====================================
=            Big Animation            =
=====================================*/
.fadeIn-Big {
    -webkit-animation: fadeIn 2s;
    animation: fadeIn 2s;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeOut-Big {
    -webkit-animation: fadeOut 2s;
    animation: fadeOut 2s;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fadeUp-Big {
    -webkit-animation: fadeUp 2s;
    animation: fadeUp 2s;
}

@-webkit-keyframes fadeUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeDown-Big {
    -webkit-animation: fadeDown 2s;
    animation: fadeDown 2s;
}

@-webkit-keyframes fadeDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeDown {
    0% {
        opacity: 0;
        transform: translateY(-100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeL-Big {
    -webkit-animation: fadeL 2s;
    animation: fadeL 2s;
}

@-webkit-keyframes fadeL {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100px);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@keyframes fadeL {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeR-Big {
    -webkit-animation: fadeR 2s;
    animation: fadeR 2s;
}

@-webkit-keyframes fadeR {
    0% {
        opacity: 0;
        -moz-transform: translateX(100px);
    }
    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@keyframes fadeR {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/*=====================================
=            Isotope 	              =
=====================================*/
/**** Isotope Filtering ****/
.isotope-item {
    z-index: 2;
}

.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}

/**** Isotope CSS3 transitions ****/
.isotope,
.isotope .isotope-item {
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -ms-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s;
}

.isotope {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    -ms-transition-property: height, width;
    -o-transition-property: height, width;
    transition-property: height, width;
}

.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -ms-transition-property: -ms-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/
.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
    -webkit-transition-duration: 0s;
    -moz-transition-duration: 0s;
    -ms-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
}

/*======= Animations 2 =======*/
@-webkit-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }
}

@keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        -ms-transform: perspective(400px) rotateX(90deg);
        transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        -ms-transform: perspective(400px) rotateX(0deg);
        transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }
}

/*======= Typography =======*/
.ll {
    font-family: 'Lato';
    font-weight: 300;
}

.lr {
    font-family: 'Lato';
    font-weight: 400;
}

.lb {
    font-family: 'Lato';
    font-weight: 700;
}

/*======= Colors =======*/
/*======= Unique Colors =======*/
/*======= Others mixins =======*/
.lnone {
    list-style: none;
    list-style-type: none;
}

/*======= General style ======*/
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #2c2c2c;
    font-family: 'Lato';
    font-weight: 400;
}

p {
    font-family: 'Lato';
    font-weight: 400;
    color: #bbbbbb;
    font-size: 14px;
    line-height: 18px;
}

body {
    background: #222222;
}

/*======= Preloader ======*/
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2c2c2c;
    z-index: 999999;
}

#preloader #loadanimation {
    position: absolute;
    width: 100px;
    height: 50px;
    left: 50%;
    top: 50%;
    margin: -25px 0 0 -50px;
    background: url(../img/elements/loader-12.gif) center center no-repeat;
    border-bottom: 2px solid #ec3838;
}

/*======= Line Separator ======*/
.line-separator {
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 0;
    background: #2c2c2c;
}

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
--------------------------------------------------------------*/
/*--------------------------------------------------------------
1.0 - Navigation
	1.1 - Brand and collapse button
	1.2 - Search Form
	1.3 - Nav Menu
2.0 - Revolutin Slider
3.0 - jPlayer
4.0 - Albums
	4.1 - Latest Albums HOME
	4.2 - Album Style 1
	4.3 - Album Style 2
	4.4 - Album Single
	4.5 - Similar Albums
5.0 - Events
	5.1 - Event List
	5.2 - Event Gallery
	5.3 - Event Categories
	5.4 - Event Search
	5.5 - Event Single
	5.6 - Similar Events
6.0 - Tweet
7.0 - Blog Masonry
	7.1 - Latest news blog
	7.2 - Blog With Sidebar Right
	7.3 - Blog Single
	7.4 - Sidebar
8.0 - Page Header
9.0 - 404 Page
10.0 - Members
11.0 - Contact
12.0 - Gallery
12.1 - Inside Album
13.0 - Blog
14.0 - Footer
15.0 - Carousel Arrows
--------------------------------------------------------------*/
/*--------------------------------------------------------------
1.0 - Navigation
--------------------------------------------------------------*/
.navbar {
    background: #2c2c2c;
    border: none;
    border-radius: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    margin: 0;
    -moz-box-shadow: 0 1px 0 #393939;
    -webkit-box-shadow: 0 1px 0 #393939;
    box-shadow: 0 1px 0 #393939;
    /*--------------------------------------------------------------
	1.1 - Brand and collapse button
	--------------------------------------------------------------*/
    /*--------------------------------------------------------------
	1.2 - Search Form
	--------------------------------------------------------------*/
    /*--------------------------------------------------------------
	1.3 - Nav Menu
	--------------------------------------------------------------*/
}

@media screen and (max-width: 991px) {
    .navbar {
        position: relative;
    }
}

.navbar .navbar-collapse {
    border: none;
}

.navbar .navbar-header .navbar-brand {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    padding: 10px 10px;
}

@media screen and (min-width: 992px) {
    .navbar .navbar-header .navbar-brand {
        padding: 30px 0 30px 15px;
    }
}

.navbar .navbar-header .navbar-brand .colored {
    color: #ec3838;
}

.navbar .navbar-header .navbar-brand i {
    font-size: 30px !important;
    line-height: 30px;
    color: #ec3838;
    padding-right: 5px;
}

.navbar .navbar-header .navbar-brand i:before {
    margin-left: 0;
}

.navbar .navbar-header button {
    border-color: #464646;
    background-color: #2c2c2c;
    margin-top: 8px;
    margin-bottom: 7px;
}

.navbar .navbar-header button span {
    background-color: #797979 !important;
    height: 3px;
}

.navbar .navbar-header button:hover,
.navbar .navbar-header button:focus {
    border-color: #5f5f5f;
    background-color: #2c2c2c;
}

.navbar .navbar-header button:hover span,
.navbar .navbar-header button:focus span {
    background-color: #929292 !important;
}

.navbar .search-container {
    overflow: hidden;
    float: right;
    height: 30px;
    width: 30px;
    margin-top: 30px;
    border-radius: 7px;
    background: #222222;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

@media screen and (max-width: 991px) {
    .navbar .search-container {
        width: 130px;
    }
}

@media screen and (min-width: 992px) {
    .navbar .search-container:hover {
        width: 130px;
    }

    .navbar .search-container:hover input {
        display: inline-block;
        width: 100px;
        padding: 0 10px;
    }
}

.navbar .search-container input {
    font-family: 'Lato';
    font-weight: 400;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    float: left;
    width: 0;
    height: 30px;
    margin: 0;
    background: none;
    color: #fff;
    font-size: 14px;
    line-height: 30px;
    padding: 0px;
    border: 0;
    border-radius: 0;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

@media screen and (max-width: 991px) {
    .navbar .search-container input {
        display: inline-block;
        width: 100px;
        padding: 0 10px;
    }
}

.navbar .search-container input:focus {
    outline: none;
}

.navbar .search-container button {
    border: 0 !important;
    float: right;
    width: 30px;
    height: 30px;
    margin: 0;
    background: none;
    text-align: center;
    font-size: 14px;
    color: #fff;
    border-radius: 7px;
}

.navbar .search-container button:active {
    border: 0 !important;
}

@media screen and (max-width: 991px) {
    .navbar .search-container {
        float: none;
        margin-top: 15px;
    }

    .navbar .search-container input {
        float: right;
    }

    .navbar .search-container button {
        float: left;
    }
}

.navbar .navbar-nav {
    list-style: none;
    list-style-type: none;
}

@media screen and (min-width: 992px) {
    .navbar .navbar-nav {
        float: right;
    }
}

.navbar .navbar-nav > li {
    -webkit-transition: all 2s linear;
    -moz-transition: all 2s linear;
    -ms-transition: all 2s linear;
    -o-transition: all 2s linear;
    transition: all 2s linear;
    /*  Submenu */
}

.navbar .navbar-nav > li > a {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    padding: 33px 10px;
    color: #fff;
}

.navbar .navbar-nav > li > a i.icon-down-open {
    color: #ec3838;
}

.navbar .navbar-nav > li > a.selected {
    color: #ec3838;
}

.navbar .navbar-nav > li > a:hover,
.navbar .navbar-nav > li > a:focus {
    color: #ec3838;
}

@media screen and (max-width: 991px) {
    .navbar .navbar-nav > li > a {
        padding: 5px 0;
        font-size: 20px !important;
    }

    .navbar .navbar-nav > li > ul li a {
        font-size: 17px !important;
    }
}

@media screen and (min-width: 1200px) {
    .navbar .navbar-nav > li > a {
        padding: 33px 15px;
    }
}

.navbar .navbar-nav > li > ul {
    background: #2c2c2c;
    border-top: 1px solid #ec3838;
    position: absolute;
    min-width: 220px;
    padding: 0;
    list-style: none;
    list-style-type: none;
    border-radius: 0;
}

.navbar .navbar-nav > li > ul.left {
    right: 0;
    left: auto;
}

.navbar .navbar-nav > li > ul li a {
    display: block;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #dfdfdf;
    padding: 5px 5px !important;
    text-decoration: none;
    background: #2c2c2c;
}

.navbar .navbar-nav > li > ul li a:before {
    content: "- ";
}

@media screen and (min-width: 992px) {
    .navbar .navbar-nav > li > ul li a {
        padding: 10px 15px !important;
        border-bottom: 1px solid #383838;
    }

    .navbar .navbar-nav > li > ul li a:before {
        content: "";
    }

    .navbar .navbar-nav > li > ul li a:hover {
        background: #343434 !important;
        color: #ffffff;
    }
}

.navbar .navbar-nav > li > ul li a:hover {
    background: #2c2c2c;
    color: #ffffff;
}

.navbar .navbar-nav > li > ul li:last-child a {
    border-bottom: 0;
}

@media screen and (min-width: 992px) {
    .navbar .navbar-nav > li:hover > ul {
        display: block;
    }
}

/*--------------------------------------------------------------
2.0 - Revolution Slider
--------------------------------------------------------------*/
.tp-banner-container {
    position: relative;
    margin-top: 90px;
}

@media screen and (max-width: 991px) {
    .tp-banner-container {
        margin-top: 0;
    }
}

.tp-banner-container .tp-banner {
    max-height: 879px !important;
}

.tp-banner-container .tp-banner ul {
    width: auto !important;
    overflow: hidden !important;
}

.tp-banner-container .tp-banner ul li .custom-cap {
    background: rgba(44, 44, 44, 0.7);
    color: #fff;
}

.tp-banner-container .tp-banner ul li .custom-cap span.colored {
    color: #ec3838;
}

.tp-banner-container .tp-banner ul li .custom-cap.cap-1 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    padding: 15px 30px;
}

.tp-banner-container .tp-banner ul li .custom-cap.cap-1-1 {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    padding: 15px 30px;
}

.tp-banner-container .tp-banner ul li .custom-cap.cap-2 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 24px;
    line-height: 25px;
    padding: 10px 30px;
}

.tp-banner-container .tp-banner ul li .custom-cap.cap-3 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    padding: 5px 10px;
    background: rgba(0, 0, 0, 0.7);
}

.tp-banner-container .tp-banner ul li .custom-cap.cap-4 {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    padding: 8px 10px;
    background: rgba(0, 0, 0, 0.7);
}

.tp-banner-container .tp-banner ul li .custom-cap span.artist {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.tp-banner-container .tp-banner ul li .custom-button {
    text-decoration: none;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 1em;
    line-height: 1em;
    padding: 1.2em 3em 1.2em 1.5em;
    background: #2c2c2c;
    color: #fff;
}

.tp-banner-container .tp-banner ul li .custom-button i {
    font-size: 1.2em;
    color: #fff;
    line-height: 1em;
    padding-right: .8em;
}

.tp-banner-container .tp-banner ul li .custom-button.colored {
    background: #ec3838 !important;
}

.tp-banner-container .tp-banner ul li .custom-button:hover {
    background: #363636;
}

.tp-banner-container .tp-banner ul li .custom-button:hover.colored {
    background: #ea2525 !important;
}

.tp-banner-container .tp-banner ul li img {
    width: 100%;
    height: auto !important;
}

.tp-banner-container .tparrows {
    width: 28px !important;
    height: 46px !important;
    background: #2c2c2c !important;
    color: #fff;
    position: absolute !important;
    top: 50% !important;
    margin-top: -23px !important;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.tp-banner-container .tparrows:before {
    font-family: 'entypo';
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    margin-right: 0;
    line-height: 46px;
    text-align: center;
    width: 28px;
    font-size: 15px;
}

.tp-banner-container .tparrows:hover {
    background: #ec3838 !important;
    color: #fff;
}

.tp-banner-container .tparrows.tp-leftarrow {
    left: 0 !important;
}

.tp-banner-container .tparrows.tp-leftarrow:before {
    content: '\e874' !important;
}

.tp-banner-container .tparrows.tp-rightarrow {
    right: 0 !important;
}

.tp-banner-container .tparrows.tp-rightarrow:before {
    content: '\e875' !important;
}

.tp-banner-container .tp-bannertimer {
    visibility: hidden !important;
}

/*--------------------------------------------------------------
3.0 - jPlayer
--------------------------------------------------------------*/
.player {
    background: #2c2c2c;
    width: 100%;
    display: block;
    position: relative;
    border-bottom: 5px solid #525252;
}

.player .jp-gui {
    position: relative;
    width: 100%;
    display: block;
    height: 80px;
}

.player .jp-gui .toggle {
    float: left;
}

.player .jp-gui .toggle ul {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 35px;
}

.player .jp-gui .toggle ul li {
    display: block;
    width: 10px;
    height: 1px;
    margin-bottom: 3px;
    background: rgba(255, 255, 255, 0.7);
}

.player .jp-gui ul.jp-controls {
    list-style: none;
    list-style-type: none;
    padding: 0;
    padding-bottom: 25px;
    margin: 0;
    float: left;
    margin-left: 15px;
}

.player .jp-gui ul.jp-controls li {
    display: block;
    float: left;
}

.player .jp-gui ul.jp-controls li a {
    box-sizing: content-box;
    text-decoration: none;
    outline: none;
    display: block;
    position: relative;
    width: 18px;
    height: 18px;
    border: 2px solid rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 30px;
    text-align: center;
}

.player .jp-gui ul.jp-controls li a i {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    color: rgba(255, 255, 255, 0.6);
    font-size: 10px;
    line-height: 18px;
    width: 18px;
    height: 18px;
    text-align: center;
    margin: 0;
}

.player .jp-gui ul.jp-controls li a.jp-mute,
.player .jp-gui ul.jp-controls li a.jp-unmute {
    border-color: rgba(255, 255, 255, 0);
    margin-right: 0px;
}

.player .jp-gui ul.jp-controls li a.jp-mute i,
.player .jp-gui ul.jp-controls li a.jp-unmute i {
    font-size: 13px;
    width: 16px;
}

.player .jp-gui ul.jp-controls li a.jp-unmute i {
    color: rgba(255, 255, 255, 0.3);
}

.player .jp-gui ul.jp-controls li a.jp-play i {
    margin-left: 1px;
}

.player .jp-gui ul.jp-controls li a:hover {
    border-color: rgba(255, 255, 255, 0.9);
}

.player .jp-gui ul.jp-controls li a:hover.jp-mute,
.player .jp-gui ul.jp-controls li a:hover.jp-unmute {
    border-color: rgba(255, 255, 255, 0);
}

.player .jp-gui ul.jp-controls li a:hover i {
    color: rgba(255, 255, 255, 0.9);
}

.player .jp-gui .jp-volume-bar {
    display: block;
    position: relative;
    float: left;
    margin-top: 39px;
    width: 50px;
    height: 3px;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
}

@media screen and (max-width: 260px) {
    .player .jp-gui .jp-volume-bar {
        display: none !important;
    }
}

.player .jp-gui .jp-volume-bar .jp-volume-bar-value {
    width: 0;
    height: 3px;
    background: rgba(255, 255, 255, 0.6);
}

.player .jp-gui .jp-progress {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 5px;
    overflow: hidden;
}

.player .jp-gui .jp-progress .jp-seek-bar {
    width: 0;
    height: 100%;
    cursor: pointer;
    background: #525252;
}

.player .jp-gui .jp-progress .jp-seek-bar .jp-play-bar {
    width: 0;
    height: 100%;
    background: #ec3838;
}

.player .jp-playlist ul {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 100%;
    left: 15px;
    z-index: 9998;
}

.player .jp-playlist ul li .jp-playlist-item {
    text-decoration: none;
    display: block;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 13px;
    line-height: 14px;
    padding: 13px 20px 13px 0;
    background: #1f1f1f;
    color: #fff;
    border-bottom: 1px solid #2c2c2c;
}

.player .jp-playlist ul li .jp-playlist-item:before {
    font-family: "Entypo";
    content: '\e8f4';
    display: inline-block;
    font-size: 13px;
    text-align: center;
    line-height: 14px;
    padding: 0 20px;
}

.player .jp-playlist ul li .jp-playlist-item:hover {
    background: #1a1a1a;
}

.player .jp-playlist ul li .jp-playlist-item:hover:before {
    color: #ec3838;
}

.player .jp-playlist ul li:last-child .jp-playlist-item {
    border-bottom: 0;
}

.player .jp-playlist ul li.jp-playlist-current .jp-playlist-item {
    background: #1a1a1a;
}

.player .jp-playlist ul li.jp-playlist-current .jp-playlist-item:before {
    color: #ec3838;
}

/*--------------------------------------------------------------
4.1 - Latest Albums HOME
--------------------------------------------------------------*/
.latest-albums .info i.large-icon {
    display: block;
    font-size: 50px;
    line-height: 50px;
    color: #464646;
    margin-top: 70px;
}

.latest-albums .info i.large-icon:before {
    margin: 0;
}

.latest-albums .info h2 {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 27px;
    line-height: 30px;
    margin-top: 35px;
    color: #fff;
}

.latest-albums .info h2 .colored {
    color: #ec3838;
}

.latest-albums .info p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    margin-top: 35px;
    margin-bottom: 35px;
}

.latest-albums .info .view-all {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
    color: #ec3838;
}

.latest-albums .info .view-all:hover {
    color: #ec3838;
    text-decoration: underline;
}

.latest-albums .albums .album {
    float: left;
    width: 32%;
    max-width: 256px;
    margin-right: 2%;
    margin-top: 70px;
}

@media screen and (max-width: 600px) {
    .latest-albums .albums .album {
        float: none;
        width: 100%;
        margin: 50px auto 0;
        max-width: 256px;
        clear: both;
    }

    .latest-albums .albums .album.last {
        margin: 50px auto 0 !important;
    }
}

.latest-albums .albums .album.last {
    margin-right: 0;
}

.latest-albums .albums .album .cover {
    position: relative;
    overflow: hidden;
}

.cover img {
    /*min-width: 220px;*/
    min-height: 220px;
}
.cover.mixtapeDetail img {
    min-width: auto !important;
}

.latest-albums .albums .album .cover .title {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    color: #fff;
    padding: 5px 10px 5px 7px;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 18px;
    bottom: 67px;
}

.latest-albums .albums .album .cover .artist {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    padding: 5px 30px 5px 7px;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 18px;
    bottom: 20px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.latest-albums .albums .album .cover .artist .artist-2 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
}

.latest-albums .albums .album .cover:hover .title {
    animation-name: fadeDown;
    -webkit-animation-name: fadeDown;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    background: rgba(0, 0, 0, 0.85);
}

.latest-albums .albums .album .cover:hover .artist {
    animation-name: fadeUp;
    -webkit-animation-name: fadeUp;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    background: rgba(0, 0, 0, 0.85);
}

.latest-albums .albums .album .info {
    width: 100%;
    background: #181818;
    padding: 18px;
}

.latest-albums .albums .album .info a {
    text-decoration: none;
    outline: none;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    margin: 0;
    color: #ec3838;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.latest-albums .albums .album .info a:hover {
    color: rgba(255, 255, 255, 0.8);
}

.latest-albums .albums .album .info p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5f5f5f;
    margin-top: 7px;
    margin-bottom: 0;
}

.latest-albums .albums .album .info p i {
    font-size: 14px;
    line-height: 16px;
}

/*--------------------------------------------------------------
4.2 - Album Style 1
--------------------------------------------------------------*/
.album-1 {
    /*--------------------------------------------------------------
	4.2 - Album Style 2
	--------------------------------------------------------------*/
}

.album-1 .categories-event ul {
    float: none;
}

.album-1 .categories-event ul li {
    display: block;
    float: left;
    width: 9.5%;
    margin-right: 0.5%;
}

@media screen and (max-width: 767px) {
    .album-1 .categories-event ul li {
        width: 19.5%;
    }

    .album-1 .categories-event ul li:last-child {
        margin-right: 0;
    }
}

.album-1 .categories-event ul li:last-child {
    margin-right: 0;
}

.album-1 .categories-event ul li a {
    text-align: center;
    width: auto;
    padding: 0;
    background: #2c2c2c;
}

.album-1 .event-list {
    padding: 0 10px;
}

.album-1 .event-list > ul {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.album-1 .event-list > ul li {
    display: block;
    padding: 5px;
    width: 20%;
    max-width: 380px;
}

@media screen and (max-width: 1199px) {
    .album-1 .event-list > ul li {
        width: 24.95%;
    }
}

@media screen and (max-width: 991px) {
    .album-1 .event-list > ul li {
        width: 33.29%;
    }
}

@media screen and (max-width: 767px) {
    .album-1 .event-list > ul li {
        width: 49.66%;
    }
}

@media screen and (max-width: 500px) {
    .album-1 .event-list > ul li {
        width: 100%;
        margin: 0 auto;
    }
}

.album-1 .event-list > ul li .album {
    max-width: 380px;
}

.album-1 .event-list > ul li .album .cover {
    position: relative;
    overflow: hidden;
}

.album-1 .event-list > ul li .album .cover .title {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    color: #fff;
    padding: 5px 10px 5px 7px;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 18px;
    bottom: 67px;
}

.album-1 .event-list > ul li .album .cover .artist {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    padding: 5px 30px 5px 7px;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 18px;
    bottom: 20px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.album-1 .event-list > ul li .album .cover .artist .artist-2 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
}

.album-1 .event-list > ul li .album .cover:hover .title {
    animation-name: fadeDown;
    -webkit-animation-name: fadeDown;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    display: block;
}

.album-1 .event-list > ul li .album .cover:hover .artist {
    display: block;
    animation-name: fadeUp;
    -webkit-animation-name: fadeUp;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
}

.album-1 .event-list > ul li .album .info {
    width: 220px;
    background: #181818;
    padding: 18px;
}

.album-1 .event-list > ul li .album .info a {
    text-decoration: none;
    outline: none;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    margin: 0;
    color: #ec3838;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    white-space: nowrap;
}

.album-1 .event-list > ul li .album .info a:hover {
    color: rgba(255, 255, 255, 0.8);
}

.album-1 .event-list > ul li .album .info p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #626262;
    margin-top: 7px;
    margin-bottom: 0;
}

.album-1 .event-list > ul li .album .info p i {
    font-size: 14px;
    line-height: 16px;
    color: #626262;
}

.album-1.album-2 .event-list {
    padding: 0 13px;
}

.album-1.album-2 .event-list > ul li {
    padding: 2px;
    width: 33.3%;
    max-width: 383px;
}

@media screen and (max-width: 767px) {
    .album-1.album-2 .event-list > ul li {
        width: 49.66%;
    }
}

@media screen and (max-width: 500px) {
    .album-1.album-2 .event-list > ul li {
        width: 100%;
        margin: 0 auto;
    }
}

.album-1.album-2 .event-list > ul li .album {
    max-width: 380px;
}

.album-1.album-2 .event-list > ul li .album .cover .title {
    font-size: 9px;
    line-height: 10px;
    padding: 5px 10px 5px 7px;
    bottom: 67px;
}

.album-1.album-2 .event-list > ul li .album .cover .artist {
    font-size: 12px;
    line-height: 16px;
    padding: 5px 30px 5px 7px;
    bottom: 20px;
}

.album-1.album-2 .event-list > ul li .album .cover .artist .artist-2 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
}

@media screen and (min-width: 992px) {
    .album-1.album-2 .event-list > ul li .album .cover .title {
        font-size: 14px;
        line-height: 16px;
        padding: 7px 20px 7px 20px;
        bottom: 100px;
    }

    .album-1.album-2 .event-list > ul li .album .cover .artist {
        font-size: 17px;
        line-height: 20px;
        padding: 7px 50px 7px 20px;
        bottom: 40px;
    }

    .album-1.album-2 .event-list > ul li .album .cover .artist .artist-2 {
        font-family: 'Lato';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
}

/*--------------------------------------------------------------
4.4 - Album Single
--------------------------------------------------------------*/
@media screen and (min-width: 768px) {
    .single-album .section-01 {
        background: #2c2c2c;
    }
}

.single-album .section-01 .album {

}

.single-album .section-01 .album .cover {
    position: relative;
    overflow: hidden;
}

.single-album .section-01 .album .cover .title {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    color: #fff;
    padding: 5px 10px 5px 7px;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 18px;
    bottom: 67px;
}

.single-album .section-01 .album .cover .artist {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    padding: 5px 30px 5px 7px;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 18px;
    bottom: 20px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.single-album .section-01 .album .cover .artist .artist-2 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
}

@media screen and (min-width: 768px) {
    .single-album .section-01 .album .cover .title {
        font-size: 14px;
        line-height: 16px;
        padding: 7px 20px 7px 20px;
        bottom: 100px;
    }

    .single-album .section-01 .album .cover .artist {
        font-size: 17px;
        line-height: 20px;
        padding: 7px 50px 7px 20px;
        bottom: 40px;
    }

    .single-album .section-01 .album .cover .artist .artist-2 {
        font-family: 'Lato';
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
}

.single-album .section-01 .player {
    width: 100%;
    float: none;
    border: none;
}

.single-album .section-01 .player .jp-gui {
    padding-left: 15px;
}

.single-album .section-01 .player .jp-gui .jp-progress {
    margin-left: -15px;
}

.single-album .section-01 .player .jp-playlist ul {
    position: relative;
    display: block;
    left: 0;
    margin-top: 5px;
}

.single-album .section-01 .player .jp-playlist ul li .jp-playlist-item {
    background: #2c2c2c;
    border-color: #464646;
    line-height: 13.3px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.single-album .section-01 .player .jp-playlist ul li .jp-playlist-item:hover {
    color: #ec3838;
}

@media screen and (max-width: 767px) {
    .single-album .section-01 .player .jp-gui {
        background: #2c2c2c;
    }
}

.single-album .album-info {
    margin-top: 5px;
}

.single-album .album-info ul {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.single-album .album-info ul li {
    border-bottom: 1px solid #464646;
    padding: 10px 0;
}

.single-album .album-info ul li h5 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: #ec3838;
    text-transform: uppercase;
    margin-top: 10px;
}

.single-album .album-info ul li p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #fff;
    text-transform: uppercase;
    margin: 10px 0 0;
}

.single-album .album-content {
    margin-top: 30px;
}

.single-album .album-content h4 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 17px;
    line-height: 18px;
    margin-top: 20px;
    margin-bottom: 0;
    color: #ec3838;
}

.single-album .album-content p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 0;
}

.single-album .album-content a.buy {
    display: block;
    float: left;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 13px;
    color: #fff;
    line-height: 14px;
    padding: 13px 50px;
    text-transform: uppercase;
    background: #ec3838;
    margin-top: 30px;
    text-decoration: none;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.single-album .album-content a.buy:hover {
    background: #464646;
}

/*--------------------------------------------------------------
4.5 - Similar Albums
--------------------------------------------------------------*/
.similar-albums .heading-01 {
    font-family: 'Lato';
    font-weight: 400;
    color: #ec3838;
    padding: 0 0 20px;
    border-bottom: 1px solid #464646;
}

.similar-albums .margins {
    margin: 0 -0.5%;
}

.similar-albums .album {
    float: left;
    width: 19%;
    max-width: 400px;
    margin-right: 0.5%;
    margin-left: 0.5%;
    margin-top: 25px;
    border: 2px solid transparent;
}

.sponsored-album {
    border: 3px solid #ec3838;
}

.ribbon-box {
    position: relative;
}

.ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}

.ribbon-left {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}

@media screen and (max-width: 991px) {
    .similar-albums .album {
        width: 32.33333%;
        max-width: 400px;
    }
}

@media screen and (max-width: 600px) {
    .similar-albums .album {
        float: none;
        width: 100%;
        margin: 25px auto 0;
        max-width: 400px;
        clear: both;
    }
}

.similar-albums .album .cover {
    position: relative;
    overflow: hidden;
}

.similar-albums .album .cover .title {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    color: #fff;
    padding: 5px 10px 5px 7px;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 18px;
    bottom: 67px;
}

.similar-albums .album .cover .artist {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    padding: 5px 30px 5px 7px;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 18px;
    bottom: 20px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.similar-albums .album .cover .artist .artist-2 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
}

.similar-albums .album .cover:hover .title {
    animation-name: fadeDown;
    -webkit-animation-name: fadeDown;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    background: rgba(0, 0, 0, 0.85);
}

.similar-albums .album .cover:hover .artist {
    animation-name: fadeUp;
    -webkit-animation-name: fadeUp;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    background: rgba(0, 0, 0, 0.85);
}

.similar-albums .album .info {
    width: 100%;
    background: #181818;
    padding: 18px;
}

.similar-albums .album .info a {
    text-decoration: none;
    outline: none;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    margin: 0;
    color: #ec3838;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    white-space: nowrap;
}

.similar-albums .album .info a:hover {
    color: rgba(255, 255, 255, 0.8);
}

.similar-albums .album .info p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #5f5f5f;
    margin-top: 7px;
    margin-bottom: 0;
}

.similar-albums .album .info p i {
    font-size: 14px;
    line-height: 16px;
}

/*--------------------------------------------------------------
5.1 - Event List
--------------------------------------------------------------*/
.event-list-container {
    /* === Month === */
    /* === Event list === */
}

.event-list-container h3.month {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
}

.event-list-container h3.month span.colored {
    color: #ec3838;
}

@media screen and (max-width: 767px) {
    .event-list-container h3.month {
        text-align: center;
        padding-bottom: 10px;
    }
}

.event-list-container .event-list ul {
    list-style: none;
    list-style-type: none;
    margin: 0;
    margin-top: 30px;
    padding: 0;
}

.event-list-container .event-list ul li {
    display: block;
    background: #464646;
    width: 100%;
    margin-top: 13px;
    clear: both;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.event-list-container .event-list ul li:hover {
    background: #3e3e3e;
}

.event-list-container .event-list ul li .date,
.event-list-container .event-list ul li .artist,
.event-list-container .event-list ul li .location {
    float: left;
    line-height: 60px;
    font-size: 13px;
    font-family: 'Lato';
    font-weight: 700;
    color: #fff;
    background: none;
    overflow: hidden;
    text-align: left;
}

.event-list-container .event-list ul li .date i,
.event-list-container .event-list ul li .artist i,
.event-list-container .event-list ul li .location i {
    color: #242424;
    padding-right: 25px;
    font-size: 22px;
    line-height: 100%;
}

.event-list-container .event-list ul li .date span.separator,
.event-list-container .event-list ul li .artist span.separator,
.event-list-container .event-list ul li .location span.separator {
    display: block;
    width: 1px;
    height: 30px;
    margin-top: 15px;
    float: right;
    background: #242424;
}

.event-list-container .event-list ul li .date {
    width: 20%;
    padding-left: 4%;
}

.event-list-container .event-list ul li .artist {
    width: 35%;
    padding-left: 4%;
}

.event-list-container .event-list ul li .artist .number {
    min-width: 30px;
}

.event-list-container .event-list ul li .location {
    width: 35%;
    padding-left: 4%;
}

.event-list-container .event-list ul li a.highlighted-link {
    text-align: center;
    text-decoration: none;
    outline: none;
    float: left;
    border: none !important;
    border-radius: 0;
    background: #ec3838;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 13px;
    line-height: 60px;
    color: #fff;
    width: 10%;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.event-list-container .event-list ul li a.sold {
    background: #393939;
}

.event-list-container .event-list ul li a.highlighted-link:hover {
    background: #393939;
}

@media screen and (max-width: 991px) {
    .event-list-container .event-list ul li .date,
    .event-list-container .event-list ul li .artist,
    .event-list-container .event-list ul li .location {
        width: 28%;
        padding-left: 5px;
    }

    .event-list-container .event-list ul li .date i,
    .event-list-container .event-list ul li .artist i,
    .event-list-container .event-list ul li .location i {
        padding-right: 5px;
    }

    .event-list-container .event-list ul li a {
        width: 16%;
    }
}

@media screen and (max-width: 767px) {
    .event-list-container .event-list ul li .date,
    .event-list-container .event-list ul li .artist,
    .event-list-container .event-list ul li .location {
        width: 100%;
        line-height: 30px;
        text-align: left;
        padding: 0 15px;
        border-bottom: 1px solid #242424;
    }

    .event-list-container .event-list ul li .date i,
    .event-list-container .event-list ul li .artist i,
    .event-list-container .event-list ul li .location i {
        padding-right: 5px;
        line-height: 30px;
    }

    .event-list-container .event-list ul li a {
        width: 100% !important;
        line-height: 30px;
    }

    .event-list-container .event-list ul li span.separator {
        display: none !important;
    }
}

.event-list-container .event-list a.show-all {
    display: block;
    width: 100%;
    height: 70px;
    text-align: center;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 14px;
    line-height: 70px;
    color: #fff;
    background: #464646;
    margin-top: 15px;
    text-decoration: none;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.event-list-container .event-list a.show-all:hover {
    background: #ec3838;
}

/*--------------------------------------------------------------
5.2 - Events Gallery
--------------------------------------------------------------*/
.event-gallery-container .event-list > ul {
    list-style: none;
    list-style-type: none;
    margin: 0;
    margin-top: 40px;
    padding: 0;
}

.event-gallery-container .event-list > ul > li {
    padding: 30px 15px 0;
}

@media screen and (max-width: 767px) {
    .event-gallery-container .event-list > ul > li {
        width: 50%;
        padding: 30px 15px 0;
    }
}

@media screen and (max-width: 600px) {
    .event-gallery-container .event-list > ul > li {
        width: 100%;
        padding: 30px 15px 0;
        margin: 0 auto;
        max-width: 390px;
    }
}

.event-gallery-container .event-list > ul > li .wrapper-ev {
    display: block;
    overflow: hidden;
    position: relative;
    background: #ec3838;
}

.event-gallery-container .event-list > ul > li .wrapper-ev img {
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.event-gallery-container .event-list > ul > li .wrapper-ev i.icon-ccw {
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 25px;
    color: #fff;
    top: 0;
    left: 50%;
    margin-top: -40px;
    margin-left: -20px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.event-gallery-container .event-list > ul > li .wrapper-ev .hover-info {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(24, 24, 24, 0.9);
    padding: 22px 20px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.event-gallery-container .event-list > ul > li .wrapper-ev .hover-info h5 {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
}

.event-gallery-container .event-list > ul > li .wrapper-ev .hover-info span.line {
    display: block;
    width: 50px;
    height: 1px;
    background: #ec3838;
    margin-top: 12px;
}

.event-gallery-container .event-list > ul > li .wrapper-ev .hover-info ul.info {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 15px 0 0;
}

.event-gallery-container .event-list > ul > li .wrapper-ev .hover-info ul.info li {
    display: inline-block;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 5px;
}

.event-gallery-container .event-list > ul > li .wrapper-ev .hover-info ul.info li i {
    font-size: 12px;
    line-height: 16px;
    color: #ec3838;
    margin: 0;
}

.event-gallery-container .event-list > ul > li .wrapper-ev:hover img {
    opacity: .1;
}

.event-gallery-container .event-list > ul > li .wrapper-ev:hover i.icon-ccw {
    top: 35%;
    margin-top: -20px;
}

@media screen and (max-width: 1200px) {
    .event-gallery-container .event-list > ul > li .wrapper-ev:hover i.icon-ccw {
        top: 28%;
    }
}

@media screen and (max-width: 991px) {
    .event-gallery-container .event-list > ul > li .wrapper-ev:hover i.icon-ccw {
        top: 18%;
    }
}

@media screen and (max-width: 767px) {
    .event-gallery-container .event-list > ul > li .wrapper-ev:hover i.icon-ccw {
        top: 30%;
    }
}

.event-gallery-container .event-list > ul > li .wrapper-ev:hover .hover-info {
    background: #181818;
}

/*--------------------------------------------------------------
5.3 - Events Categories
--------------------------------------------------------------*/
.categories-event > ul {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
    float: right;
}

@media screen and (max-width: 767px) {
    .categories-event > ul {
        float: none;
        text-align: center;
    }

    .categories-event > ul li {
        margin: 10px auto 0;
    }
}

.categories-event > ul li {
    display: inline-block;
    height: 28px;
}

.categories-event > ul li a {
    display: block;
    height: 28px;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
    color: #fff;
    padding: 0 20px;
    background: #464646;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.categories-event > ul li a.selected {
    background: #ec3838;
    color: #fff;
}

.categories-event > ul li a:hover {
    background: #ec3838;
    color: #fff;
}

/*--------------------------------------------------------------
5.4 - Events Search
--------------------------------------------------------------*/
.search-event {
    display: block;
    height: 28px;
}

.search-event input {
    font-family: 'Lato';
    font-weight: 400;
    float: left;
    width: 85%;
    height: 28px;
    margin: 0;
    background: #464646;
    color: #fff;
    font-size: 13px;
    line-height: 28px;
    padding: 0px 10px;
    border: 0;
    border-radius: 0;
}

.search-event input:focus {
    outline: none;
}

.search-event button {
    border: 0 !important;
    float: left;
    width: 13%;
    height: 28px;
    margin: 0 2% 0 0;
    background: #464646;
    text-align: center;
    font-size: 14px;
    color: #fff;
    border-radius: 0;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.search-event button:active {
    border: 0 !important;
}

.search-event button:hover {
    background: #ec3838;
}

/*--------------------------------------------------------------
5.5 - Events Single
--------------------------------------------------------------*/
@media screen and (max-width: 767px) {
    .single-event .event {
        max-width: 390px;
        margin-left: auto;
        margin-right: auto;
    }
}

.single-event .event .wrapper-ev {
    display: block;
    overflow: hidden;
    position: relative;
    background: #ec3838;
}

.single-event .event .wrapper-ev img {
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.single-event .event .wrapper-ev .hover-info {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(24, 24, 24, 0.9);
    padding: 22px 20px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.single-event .event .wrapper-ev .hover-info h5 {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
}

.single-event .event .wrapper-ev .hover-info span.line {
    display: block;
    width: 50px;
    height: 1px;
    background: #ec3838;
    margin-top: 12px;
}

.single-event .event .wrapper-ev .hover-info ul.info {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 15px 0 0;
}

.single-event .event .wrapper-ev .hover-info ul.info li {
    display: inline-block;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 5px;
}

.single-event .event .wrapper-ev .hover-info ul.info li i {
    font-size: 12px;
    line-height: 16px;
    color: #ec3838;
    margin: 0;
}

.single-event .googleMap {
    position: relative;
    height: 360px;
}

@media screen and (max-width: 1199px) {
    .single-event .googleMap {
        height: 293px;
    }
}

@media screen and (max-width: 991px) {
    .single-event .googleMap {
        height: 345px;
    }
}

@media screen and (max-width: 767px) {
    .single-event .googleMap {
        max-width: 390px;
        margin-left: auto;
        margin-right: auto;
        height: 345px;
        margin-top: 30px;
    }
}

.single-event .googleMap #googleMap {
    display: block;
    width: 100% !important;
    height: 100% !important;
}

.single-event .ev-info {
    margin-top: 30px;
}

.single-event .ev-info ul {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.single-event .ev-info ul li {
    border-bottom: 1px solid #464646;
    padding: 10px 0;
}

.single-event .ev-info ul li h5 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: #ec3838;
    text-transform: uppercase;
    margin-top: 10px;
}

.single-event .ev-info ul li p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #fff;
    text-transform: uppercase;
    margin: 10px 0 0;
}

.single-event .ev-content {
    margin-top: 30px;
}

.single-event .ev-content h4 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 17px;
    line-height: 18px;
    margin-top: 20px;
    margin-bottom: 0;
    color: #ec3838;
}

.single-event .ev-content p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 0;
}

.single-event .ev-content a.buy {
    display: block;
    float: left;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 13px;
    color: #fff;
    line-height: 14px;
    padding: 13px 50px;
    text-transform: uppercase;
    background: #ec3838;
    margin-top: 30px;
    text-decoration: none;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.single-event .ev-content a.buy:hover {
    background: #464646;
}

.single-event .ev-content ul.social {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 30px;
    float: right;
}

.single-event .ev-content ul.social li {
    display: block;
    float: left;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 0;
    color: #ec3838;
    margin-right: 5px;
}

.single-event .ev-content ul.social li:first-child {
    margin-right: 15px;
}

.single-event .ev-content ul.social li a {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-decoration: none;
    outline: none;
    position: relative;
    background: #fff;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.single-event .ev-content ul.social li a i {
    font-size: 10px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: #181818;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.single-event .ev-content ul.social li a:hover {
    background: #464646;
}

.single-event .ev-content ul.social li a:hover i {
    color: #fff;
}

/*--------------------------------------------------------------
5.6 - Similar Events
--------------------------------------------------------------*/
.similar-events .heading-01 {
    font-family: 'Lato';
    font-weight: 400;
    color: #ec3838;
    padding: 0 0 20px;
    border-bottom: 1px solid #464646;
}

.similar-events .wrapper-ev {
    display: block;
    overflow: hidden;
    position: relative;
    background: #ec3838;
    float: left;
    width: 23.5%;
    max-width: 360px;
    margin-right: 2%;
    margin-top: 25px;
}

.similar-events .wrapper-ev.last {
    margin-right: 0;
}

@media screen and (max-width: 767px) {
    .similar-events .wrapper-ev {
        float: none;
        width: 100%;
        margin: 25px auto 0;
        max-width: 360px;
        clear: both;
    }

    .similar-events .wrapper-ev.last {
        margin: 25px auto 0 !important;
    }
}

.similar-events .wrapper-ev img {
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.similar-events .wrapper-ev i.icon-ccw {
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 25px;
    color: #fff;
    top: 0;
    left: 50%;
    margin-top: -40px;
    margin-left: -20px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.similar-events .wrapper-ev .hover-info {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(24, 24, 24, 0.9);
    padding: 15px 15px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.similar-events .wrapper-ev .hover-info h5 {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
}

.similar-events .wrapper-ev .hover-info span.line {
    display: block;
    width: 50px;
    height: 1px;
    background: #ec3838;
    margin-top: 12px;
}

.similar-events .wrapper-ev .hover-info ul.info {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 5px 0 0;
}

.similar-events .wrapper-ev .hover-info ul.info li {
    display: inline-block;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
    text-transform: uppercase;
    margin-right: 10px;
}

.similar-events .wrapper-ev .hover-info ul.info li i {
    font-size: 12px;
    line-height: 16px;
    color: #ec3838;
    margin: 0;
}

.similar-events .wrapper-ev .hover-info ul.info li i:before {
    margin: 0;
}

.similar-events .wrapper-ev:hover img {
    opacity: .1;
}

.similar-events .wrapper-ev:hover i.icon-ccw {
    top: 35%;
    margin-top: -20px;
}

@media screen and (max-width: 1200px) {
    .similar-events .wrapper-ev:hover i.icon-ccw {
        top: 28%;
    }
}

@media screen and (max-width: 991px) {
    .similar-events .wrapper-ev:hover i.icon-ccw {
        top: 18%;
    }
}

@media screen and (max-width: 767px) {
    .similar-events .wrapper-ev:hover i.icon-ccw {
        top: 30%;
    }
}

.similar-events .wrapper-ev:hover .hover-info {
    background: #181818;
}

/*--------------------------------------------------------------
6.0 - Tweet
--------------------------------------------------------------*/
.tweet {
    margin-top: 80px;
    background: url(../img/elements/tweet-BG.jpg);
    -webkit-background-size: cover;
    background-size: cover;
}

.tweet .hover-layer {
    width: 100%;
    height: 100%;
    background-image: rgba(236, 56, 56, 0.95);
    padding-top: 70px;
    padding-bottom: 70px;
    background-image: -moz-linear-gradient(bottom, rgba(220, 21, 21, 0.95) 0%, rgba(236, 56, 56, 0.95) 100%);
    background-image: -o-linear-gradient(bottom, rgba(220, 21, 21, 0.95) 0%, rgba(236, 56, 56, 0.95) 100%);
    background-image: -webkit-linear-gradient(bottom, rgba(220, 21, 21, 0.95) 0%, rgba(236, 56, 56, 0.95) 100%);
    background-image: linear-gradient(bottom, rgba(220, 21, 21, 0.95) 0%, rgba(236, 56, 56, 0.95) 100%);
}

.tweet .carousel .item {
    text-align: center;
}

.tweet .carousel .item i {
    font-size: 50px;
    line-height: 50px;
    color: #fff;
}

.tweet .carousel .item p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    max-width: 90%;
    margin: 50px auto 0;
    clear: both;
}

.tweet .carousel .item .line {
    display: block;
    width: 60%;
    height: 1px;
    background: #7f0c0c;
    margin: 50px auto 0;
    clear: both;
}

.tweet .carousel .item span.date {
    display: block;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7f0c0c;
    margin: 25px auto 0;
    text-transform: uppercase;
}

/*--------------------------------------------------------------
7.0 - Blog Masonry
--------------------------------------------------------------*/
.blog {
    padding-top: 40px;
}

.blog .masonry-trigger {
    display: block;
    width: 1px;
    height: 1px;
    position: fixed;
    left: -1;
    bottom: -1;
    background: rgba(255, 255, 255, 0);
}

@media screen and (max-width: 767px) {
    .blog .masonry-trigger {
        display: none;
    }
}

.blog .blog-post {
    margin-top: 30px;
    max-width: 390px;
}

@media screen and (min-width: 992px) {
    .blog .blog-post {
        width: 33.33%;
        padding: 0 15px;
    }
}

@media screen and (max-width: 767px) {
    .blog .blog-post {
        width: 100% !important;
        max-width: 390px !important;
        margin: 30px auto 0 !important;
    }
}

.blog .blog-post .content {
    background: #2c2c2c;
    padding: 20px;
}

.blog .blog-post .content h4 {
    font-family: 'Lato';
    font-weight: 400;
    color: #ec3838;
    margin: 0;
}

.blog .blog-post .content ul.info {
    list-style: none;
    list-style-type: none;
    margin: 0;
    padding: 15px 0 10px;
}

.blog .blog-post .content ul.info li {
    display: inline-block;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    margin-right: 5px;
}

.blog .blog-post .content ul.info li span {
    color: #ec3838;
    font-size: 12px;
    line-height: 16px;
    padding-right: 5px;
}

.blog .blog-post .content hr {
    border-color: #464646;
    margin: 10px 0;
}

.blog .blog-post .content p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    padding-top: 5px;
}

.blog .blog-post .content a.read-more {
    text-decoration: none;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 40px;
    height: 40px;
    display: block;
    width: 100%;
    margin-top: 25px;
    background: #ec3838;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.blog .blog-post .content a.read-more:hover {
    background: #464646;
    color: #fff;
}

.blog .blog-post .social {
    width: 100%;
    background: #181818;
    padding: 12px 20px;
}

.blog .blog-post .social .likes {
    display: inline-block;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #505050;
}

.blog .blog-post .social .likes i {
    font-size: 13px;
    line-height: 16px;
    color: #505050;
    margin-right: 5px;
}

.blog .blog-post .social .social-icons {
    display: inline-block;
    float: right;
}

.blog .blog-post .social .social-icons i {
    font-size: 13px;
    line-height: 16px;
    color: #505050;
    margin-right: 5px;
}

/*--------------------------------------------------------------
7.1 - Latest news blog
--------------------------------------------------------------*/
.latest-blog {
    background: #181818;
    padding: 45px 0 50px 0;
}

.latest-blog i {
    display: inline-block;
    font-size: 40px;
    line-height: 40px;
    color: #464646;
    margin-right: 35px;
}

.latest-blog h3 {
    display: inline-block;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 27px;
    line-height: 40px;
    margin: 0;
    color: #fff;
}

.latest-blog h3 .colored {
    color: #ec3838;
}

/*--------------------------------------------------------------
7.2 - Blog
--------------------------------------------------------------*/
@media screen and (min-width: 1200px) {
    .blog-1 .blog-article {
        padding-right: 50px;
    }
}

.blog-1 .blog-article .article .blog-article-content a {
    text-decoration: none;
    outline: none;
}

.blog-1 .blog-article .article .blog-article-content a h3 {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 19px;
    color: #ec3838;
    margin: 30px 0 0 0;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.blog-1 .blog-article .article .blog-article-content a h3:hover {
    color: #464646;
}

.blog-1 .blog-article .article .blog-article-content ul.article-info {
    list-style: none;
    list-style-type: none;
    padding: 20px 0 0 0;
    margin: 0;
}

.blog-1 .blog-article .article .blog-article-content ul.article-info li {
    display: inline-block;
}

.blog-1 .blog-article .article .blog-article-content ul.article-info li a {
    text-decoration: none;
    color: #fff;
    font-size: 12px;
    padding-right: 15px;
    padding-left: 15px;
    border-right: 1px solid #464646;
    font-family: 'Lato';
    font-weight: 400;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

@media screen and (max-width: 500px) {
    .blog-1 .blog-article .article .blog-article-content ul.article-info li a {
        border: none;
    }
}

.blog-1 .blog-article .article .blog-article-content ul.article-info li a i {
    color: #fff;
}

.blog-1 .blog-article .article .blog-article-content ul.article-info li a:hover {
    color: #ec3838;
}

.blog-1 .blog-article .article .blog-article-content ul.article-info li:first-child a {
    padding-left: 0;
}

.blog-1 .blog-article .article .blog-article-content p {
    font-family: 'Lato';
    font-weight: 400;
    color: #fff;
    font-size: 15px;
    line-height: 20px;
    margin-top: 20px;
}

.blog-1 .blog-article .article .blog-article-content a.continue-reading {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #ec3838;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.blog-1 .blog-article .article .blog-article-content a.continue-reading:hover {
    color: rgba(255, 255, 255, 0.8);
}

.article-pagination {
    margin-top: 65px;
}

.article-pagination ul {
    display: block;
    padding: 25px 0;
    list-style: none;
    list-style-type: none;
    border-top: 1px solid #464646;
    border-bottom: 1px solid #464646;
}

.article-pagination ul li {
    float: left;
    margin-right: 10px;
    margin-top: 0;
}

.article-pagination ul li a {
    display: block;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 13px;
    line-height: 30px;
    width: 30px;
    height: 30px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background: #ec3838;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.article-pagination ul li a.selected {
    background: #464646;
    color: #fff;
}

.article-pagination ul li a:hover {
    background: #464646;
    color: #fff;
}

.article-pagination ul li:last-child {
    float: right;
    margin-right: 0;
}

.article-pagination ul li:last-child a {
    width: auto;
    background: none;
}

.article-pagination ul li:last-child a i {
    color: #fff;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.article-pagination ul li:last-child a:hover {
    background: none;
    color: #ec3838;
}

.article-pagination ul li:last-child a:hover i {
    color: #ec3838;
}

/*--------------------------------------------------------------
7.3 - Blog Single
--------------------------------------------------------------*/
.blog-1 .single-1 .article {
    /* Comment form */
}

.blog-1 .single-1 .article .blog-article-content p {
    font-size: 14px;
    line-height: 20px;
}

.blog-1 .single-1 .article .blog-article-content p span.highlight {
    color: rgba(255, 255, 255, 0.9);
}

.blog-1 .single-1 .article .blog-article-content ul.list {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 15px 0 0 0;
}

.blog-1 .single-1 .article .blog-article-content ul.list li {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    line-height: 25px;
}

.blog-1 .single-1 .article .blog-article-content ul.list li i {
    color: #ec3838;
    margin-right: 10px;
}

.blog-1 .single-1 .article ul.tags {
    padding: 10px 0 20px 0;
    margin: 40px 0 0 0;
    border-top: 1px solid #464646;
    border-bottom: 1px solid #464646;
}

@media screen and (min-width: 992px) {
    .blog-1 .single-1 .article ul.tags {
        padding-right: 50px;
    }
}

.blog-1 .single-1 .article ul.tags li {
    display: block;
    float: left;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 20px;
    margin-right: 10px;
    margin-top: 10px;
    background: #ec3838;
    color: #fff;
}

.blog-1 .single-1 .article ul.tags li:first-child {
    font-size: 14px;
    color: #fff;
    padding-left: 0;
    background: none;
}

.blog-1 .single-1 .article .comments .comment {
    padding: 20px;
    margin-top: 30px;
    background: #2c2c2c;
}

.blog-1 .single-1 .article .comments .comment img {
    display: block;
    float: left;
    padding: 0 20px 0 0;
}

.blog-1 .single-1 .article .comments .comment .info {
    display: block;
    padding-bottom: 10px;
}

.blog-1 .single-1 .article .comments .comment .info .name {
    display: block;
    float: left;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    color: #ec3838;
    line-height: 16px;
}

.blog-1 .single-1 .article .comments .comment .info .date {
    display: block;
    float: right;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #fff;
}

.blog-1 .single-1 .article .comments .comment .info .date i {
    color: #ec3838;
}

@media screen and (min-width: 1200px) {
    .blog-1 .single-1 .article .comments .comment .info .date {
        padding-right: 30px;
    }
}

.blog-1 .single-1 .article .comments .comment .message {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.9);
    line-height: 14px;
}

@media screen and (max-width: 767px) {
    .blog-1 .single-1 .article .comments .comment .info .name {
        clear: right;
        float: none;
        line-height: 20px;
    }

    .blog-1 .single-1 .article .comments .comment .info .date {
        clear: right;
        float: none;
        line-height: 20px;
    }
}

.blog-1 .single-1 .article .contact-form h4 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    margin-top: 40px;
    margin-bottom: 25px;
    color: #ec3838;
}

.blog-1 .single-1 .article .contact-form form .form-group .form-control {
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
    -webkit-transition: all 0.4s linear;
    -moz-transition: all 0.4s linear;
    -ms-transition: all 0.4s linear;
    -o-transition: all 0.4s linear;
    transition: all 0.4s linear;
}

.blog-1 .single-1 .article .contact-form form .form-group .form-control::-moz-placeholder {
    color: rgba(255, 255, 255, 0.9);
}

.blog-1 .single-1 .article .contact-form form .form-group .form-control:-moz-placeholder {
    color: rgba(255, 255, 255, 0.9);
}

.blog-1 .single-1 .article .contact-form form .form-group .form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.9);
}

.blog-1 .single-1 .article .contact-form form .form-group .form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.9);
}

.blog-1 .single-1 .article .contact-form form .form-group .form-control:focus {
    border-color: #ec3838;
    -webkit-box-shadow: 0 0 5px rgba(236, 56, 56, 0.5);
    box-shadow: 0 0 5px rgba(236, 56, 56, 0.5);
}

.blog-1 .single-1 .article .contact-form form .form-group .form-control:hover {
    border-color: #ec3838;
    -webkit-box-shadow: 0 0 5px rgba(236, 56, 56, 0.5);
    box-shadow: 0 0 5px rgba(236, 56, 56, 0.5);
}

.blog-1 .single-1 .article .contact-form form .form-group textarea,
.blog-1 .single-1 .article .contact-form form .form-group input {
    border-radius: 0;
    border-color: #464646;
    background: #2c2c2c;
    -webkit-box-shadow: 0;
    box-shadow: 0;
    color: #fff;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
}

.blog-1 .single-1 .article .contact-form button {
    border: none;
    border-radius: 0;
    background: #ec3838;
    color: #fff;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    padding: 12px 30px;
    margin-top: 10px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.blog-1 .single-1 .article .contact-form button:hover {
    background: #464646;
}

/*--------------------------------------------------------------
7.4 - Sidebar
--------------------------------------------------------------*/
.blog-sidebar-1,
.sidebar-1 {
    background: #2c2c2c;
    border: 1px solid #464646;
    padding: 50px 15px;
}

@media screen and (min-width: 1200px) {
    .blog-sidebar-1,
    .sidebar-1 {
        padding: 50px;
    }
}

@media screen and (max-width: 767px) {
    .blog-sidebar-1,
    .sidebar-1 {
        margin-top: 50px;
    }
}

.blog-sidebar-1 .heading,
.sidebar-1 .heading {
    margin-top: 55px;
}

.blog-sidebar-1 .heading i,
.sidebar-1 .heading i {
    display: block;
    width: 24px;
    height: 24px;
    font-size: 12px;
    text-align: center;
    line-height: 22px;
    color: #ec3838;
    border: 1px solid #ec3838;
    border-radius: 50%;
    margin-right: 15px;
    float: left;
}

.blog-sidebar-1 .heading h5,
.sidebar-1 .heading h5 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
}

.blog-sidebar-1 .search-widget,
.sidebar-1 .search-widget {
    position: relative;
    height: 46px;
    width: 100%;
    border-bottom: 2px solid #ec3838;
    overflow: hidden;
}

.blog-sidebar-1 .search-widget form.search,
.sidebar-1 .search-widget form.search {
    position: relative;
    width: 100%;
    border-radius: 0;
    height: 46px;
    overflow: hidden;
    padding-right: 46px;
}

.blog-sidebar-1 .search-widget form.search input[type="text"],
.sidebar-1 .search-widget form.search input[type="text"] {
    position: relative;
    border: none;
    background: none;
    width: 100%;
    color: #fff;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    padding: 12px 5px 12px 10px;
}

.blog-sidebar-1 .search-widget form.search input[type="text"]:focus,
.sidebar-1 .search-widget form.search input[type="text"]:focus {
    outline: none;
}

.blog-sidebar-1 .search-widget form.search button[type="submit"],
.sidebar-1 .search-widget form.search button[type="submit"] {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    width: 46px;
    height: 46px;
    padding: 0;
    overflow: hidden;
    background: #ec3838;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.blog-sidebar-1 .search-widget form.search button[type="submit"] i,
.sidebar-1 .search-widget form.search button[type="submit"] i {
    display: block;
    width: 26px;
    height: 26px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    font-size: 13px;
    line-height: 24px;
    margin: 10px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.blog-sidebar-1 .search-widget form.search button[type="submit"]:hover i,
.sidebar-1 .search-widget form.search button[type="submit"]:hover i {
    color: #2c2c2c;
    border-color: #2c2c2c;
}

.blog-sidebar-1 .search-widget form.search button[type="submit"]:focus,
.sidebar-1 .search-widget form.search button[type="submit"]:focus {
    outline: none;
}

.blog-sidebar-1 .categories ul,
.sidebar-1 .categories ul {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 15px 0 0 0;
}

.blog-sidebar-1 .categories ul li,
.sidebar-1 .categories ul li {
    border-bottom: 1px solid #464646;
}

.blog-sidebar-1 .categories ul li a,
.sidebar-1 .categories ul li a {
    display: block;
    width: 100%;
    text-decoration: none;
    margin-top: 10px;
    outline: none;
}

.blog-sidebar-1 .categories ul li a span.name,
.sidebar-1 .categories ul li a span.name {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    color: #fff;
    line-height: 34px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.blog-sidebar-1 .categories ul li a span.value,
.sidebar-1 .categories ul li a span.value {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    color: #fff;
    line-height: 34px;
    float: right;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.blog-sidebar-1 .categories ul li a:hover span.value,
.sidebar-1 .categories ul li a:hover span.value,
.blog-sidebar-1 .categories ul li a:hover span.name,
.sidebar-1 .categories ul li a:hover span.name {
    color: #ec3838;
}

.blog-sidebar-1 .featured-posts ul,
.sidebar-1 .featured-posts ul {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.blog-sidebar-1 .featured-posts ul li,
.sidebar-1 .featured-posts ul li {
    border-bottom: 1px solid #464646;
    padding: 25px 0 15px 0;
}

.blog-sidebar-1 .featured-posts ul li img,
.sidebar-1 .featured-posts ul li img {
    float: left;
    padding-right: 10px;
}

.blog-sidebar-1 .featured-posts ul li span.title,
.sidebar-1 .featured-posts ul li span.title {
    display: block;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #fff;
}

.blog-sidebar-1 .featured-posts ul li span.date,
.sidebar-1 .featured-posts ul li span.date {
    display: block;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: #a3a3a3;
    margin-bottom: 10px;
}

.blog-sidebar-1 .about .content,
.sidebar-1 .about .content {
    margin-top: 20px;
}

.blog-sidebar-1 .about .content .autor,
.sidebar-1 .about .content .autor {
    float: right;
    width: 80px;
    text-align: center;
    margin-left: 5px;
}

.blog-sidebar-1 .about .content .autor img,
.sidebar-1 .about .content .autor img {
    margin: 0 auto;
}

.blog-sidebar-1 .about .content .autor span.name,
.sidebar-1 .about .content .autor span.name {
    display: block;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
    margin-top: 6px;
}

.blog-sidebar-1 .about .content .autor span.function,
.sidebar-1 .about .content .autor span.function {
    display: block;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    color: #ec3838;
}

.blog-sidebar-1 .about .content .autor .rating,
.sidebar-1 .about .content .autor .rating {
    position: relative;
    display: block;
    white-space: nowrap;
    margin: 0 auto;
    text-align: center;
}

.blog-sidebar-1 .about .content .autor .rating i,
.sidebar-1 .about .content .autor .rating i {
    display: block;
    float: left;
    font-size: 15px;
    color: #ec3838;
    width: 14px;
    margin-top: 10px;
}

.blog-sidebar-1 .about .content p,
.sidebar-1 .about .content p {
    overflow: hidden;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    margin-top: 5px;
}

.blog-sidebar-1 .about .content p a,
.sidebar-1 .about .content p a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    white-space: nowrap;
}

.blog-sidebar-1 .about .content p a:hover,
.sidebar-1 .about .content p a:hover {
    color: #ec3838;
}

/*--------------------------------------------------------------
8.0 - Page Header
--------------------------------------------------------------*/
.page-header-2 {
    margin-top: 90px;
    margin-bottom: 0;
    padding: 30px 0 8px 0;
    display: block;
    width: 100%;
    background: #2c2c2c;
}

@media screen and (max-width: 991px) {
    .page-header-2 {
        margin-top: 0;
    }
}

.page-header-2 h3 {
    font-family: 'Lato';
    font-weight: 400;
    color: #fff;
    font-size: 22px;
    line-height: 30px;
    margin: 0;
    float: left;
}

.page-header-2 ul.breadcrumbs-custom {
    background: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    float: right;
    list-style: none;
    list-style-type: none;
}

.page-header-2 ul.breadcrumbs-custom li {
    float: left;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    color: #fff;
    letter-spacing: .1px;
    padding-left: 1px;
}

.page-header-2 ul.breadcrumbs-custom li:first-child {
    margin-right: 3px;
}

.page-header-2 ul.breadcrumbs-custom li i {
    color: #ec3838;
}

.page-header-2 ul.breadcrumbs-custom li a {
    text-decoration: none;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 11px;
    line-height: 30px;
    color: #fff;
    letter-spacing: .1px;
}

.page-header-2 ul.breadcrumbs-custom li a:hover {
    color: #ec3838;
}

/*--------------------------------------------------------------
9.0 - 404 Page
--------------------------------------------------------------*/
.page-404 {
    position: relative;
    height: 600px;
    padding: 250px 15px;
    background: url('../img/404-bg.jpg') no-repeat center;
}

.page-404 .overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../img/pixel-pattern.png') repeat;
}

.page-404 h1 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 95px;
    line-height: 95px;
    color: #464646;
    margin: 0;
    z-index: 100;
    position: relative;
    text-align: center;
    vertical-align: center;
    color: #ec3838;
}

.page-404 h4 {
    font-family: 'Lato';
    font-weight: 500;
    font-size: 40px;
    line-height: 45px;
    color: #464646;
    margin: 0;
    z-index: 100;
    position: relative;
    text-align: center;
    vertical-align: center;
    color: #ec3838;
}

.page-404 a {
    position: relative;
    z-index: 100;
    font-size: 35px;
}

.page-404 h1 span.colored {
    color: #ec3838;
}

@media screen and (max-width: 991px) {
    .page-404 {
        padding: 75px 15px;
    }

    .page-404 h1 {
        font-size: 50px;
        line-height: 50px;
    }
}

/*--------------------------------------------------------------
10.0 - Members
--------------------------------------------------------------*/
.members .member {
    margin-bottom: 70px;
    text-align: center;
}

.members .member .img-container {
    position: relative;
    max-width: 360px;
    margin: 0 auto;
    background: #ec3838;
    overflow: hidden;
}

.members .member .img-container.big {
    max-width: 548px;
}

.members .member .img-container img {
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.members .member .img-container a {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -20px;
    margin-left: -45px;
    text-decoration: none;
    outline: none;
    text-align: center;
    opacity: 0;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.members .member .img-container a.facebook {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: -45px;
}

.members .member .img-container a i {
    display: block;
    width: 40px;
    height: 40px;
    background: #2c2c2c;
    border-radius: 50%;
    font-size: 18px;
    line-height: 40px;
    color: #ec3838;
    margin: 0 auto;
    text-align: center;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.members .member .img-container a i:before,
.members .member .img-container a i:after {
    margin: 0;
}

.members .member .img-container a:hover i {
    color: rgba(255, 255, 255, 0.7);
}

.members .member .img-container:hover img {
    opacity: .1;
}

.members .member .img-container:hover a {
    display: block;
    opacity: 1;
    left: 50%;
}

.members .member .img-container:hover a.facebook {
    left: auto;
    right: 50%;
    margin-right: -45px;
}

.members .member h4 {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    margin: 20px 0 0 0;
    color: #ec3838;
}

.members  span.line {
    display: block;
    width: 80%;
    height: 1px;
    background: #ec3838;
    margin: 15px auto 0;
}

.members .member span.line {
    display: block;
    width: 120px;
    height: 1px;
    background: #ec3838;
    margin: 15px auto 0;
}

.members .member p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    margin: 20px 0 0;
}

/*--------------------------------------------------------------
11.0 - Contact
--------------------------------------------------------------*/
@media screen and (max-width: 767px) {
    .app-1 .contact-form {
        margin-top: 50px;
    }
}

.app-1 .contact-form form .form-group .form-control {
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
    -webkit-transition: all 0.4s linear;
    -moz-transition: all 0.4s linear;
    -ms-transition: all 0.4s linear;
    -o-transition: all 0.4s linear;
    transition: all 0.4s linear;
}

.app-1 .contact-form form .form-group .form-control::-moz-placeholder {
    color: rgba(255, 255, 255, 0.9);
}

.app-1 .contact-form form .form-group .form-control:-moz-placeholder {
    color: rgba(255, 255, 255, 0.9);
}

.app-1 .contact-form form .form-group .form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.9);
}

.app-1 .contact-form form .form-group .form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.9);
}

.app-1 .contact-form form .form-group .form-control:focus {
    border-color: #ec3838;
    -webkit-box-shadow: 0 0 5px rgba(236, 56, 56, 0.5);
    box-shadow: 0 0 5px rgba(236, 56, 56, 0.5);
}

.app-1 .contact-form form .form-group .form-control:hover {
    border-color: #ec3838;
    -webkit-box-shadow: 0 0 5px rgba(236, 56, 56, 0.5);
    box-shadow: 0 0 5px rgba(236, 56, 56, 0.5);
}

.app-1 .contact-form form .form-group textarea,
.app-1 .contact-form form .form-group input {
    border-radius: 0;
    border-color: #464646;
    background: #2c2c2c;
    -webkit-box-shadow: 0;
    box-shadow: 0;
    color: #fff;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
}

.app-1 .contact-form form button {
    border: 1px solid #464646;
    border-radius: 0;
    background: #2c2c2c;
    color: rgba(255, 255, 255, 0.9);
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    padding: 6px 30px;
}

.app-1 .contact-form form button:hover {
    border-color: #ec3838;
    -webkit-box-shadow: 0 0 5px rgba(236, 56, 56, 0.5);
    box-shadow: 0 0 5px rgba(236, 56, 56, 0.5);
}

.app-1 .content h4 {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 18px;
    color: #ec3838;
    margin: 0;
}

.app-1 .content p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 0;
}

.app-1 .content ul.informations {
    list-style: none;
    list-style-type: none;
    margin: 23px 0 0 0;
    padding: 0;
}

.app-1 .content ul.informations li {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    margin-top: 10px;
}

.app-1 .content ul.informations li i {
    display: block;
    float: left;
    font-size: 17px;
    line-height: 16px;
    color: #ec3838;
    padding-top: 1px;
}

/*--------------------------------------------------------------
12.0 - Gallery
--------------------------------------------------------------*/
.gallery .info i.large-icon {
    display: block;
    font-size: 50px;
    line-height: 50px;
    color: #464646;
    margin-top: 85px;
}

.gallery .info i.large-icon:before {
    margin-left: 0;
}

.gallery .info h2 {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 27px;
    line-height: 30px;
    margin-top: 35px;
    color: #fff;
}

.gallery .info h2 .colored {
    color: #ec3838;
}

.gallery .info p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    margin-top: 35px;
    margin-bottom: 35px;
}

.gallery .info .view-all {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
    color: #ec3838;
}

.gallery .info .view-all:hover {
    color: #ec3838;
    text-decoration: underline;
}

.gallery .event {
    margin-top: 85px;
}

@media screen and (max-width: 767px) {
    .gallery .event {
        margin-top: 40px;
    }
}

.gallery .event .cover {
    overflow: hidden;
    max-width: 360px;
    position: relative;
    background: #ec3838;
}

.gallery .event .cover img {
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.gallery .event .cover a {
    font-family: 'Lato';
    font-weight: 400;
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
    line-height: 50px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    text-align: center;
    bottom: 0;
    background: rgba(24, 24, 24, 0.8);
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.gallery .event .cover a:before {
    color: #ec3838;
}

.gallery .event .cover a:hover {
    background: #181818;
    color: rgba(255, 255, 255, 0.9);
}

.gallery .event .cover:hover img {
    -webkit-transform: scale(1.25);
    /* Safari and Chrome */
    -moz-transform: scale(1.25);
    /* Firefox */
    -ms-transform: scale(1.25);
    /* IE 9 */
    -o-transform: scale(1.25);
    /* Opera */
    transform: scale(1.25);
}

.gallery .event .video-embeded {
    position: relative;
    max-width: 360px;
}

.gallery .event .video-embeded iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    border: none !important;
    z-index: 1000 !important;
}

/*--------------------------------------------------------------
12.1 - Inside Album
--------------------------------------------------------------*/
.inside-album .photo,
.inside-album .video {
    padding: 15px;
}

.inside-album .photo .cover,
.inside-album .video .cover {
    overflow: hidden;
    max-width: 360px;
    position: relative;
    background: #ec3838;
}

.inside-album .photo .cover img,
.inside-album .video .cover img {
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.inside-album .photo .cover a,
.inside-album .video .cover a {
    font-family: 'Lato';
    font-weight: 400;
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
    line-height: 50px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    text-align: center;
    bottom: 0;
    background: rgba(24, 24, 24, 0.8);
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.inside-album .photo .cover a:before,
.inside-album .video .cover a:before {
    color: #ec3838;
}

.inside-album .photo .cover a:hover,
.inside-album .video .cover a:hover {
    background: #181818;
    color: rgba(255, 255, 255, 0.9);
}

.inside-album .photo .cover:hover img,
.inside-album .video .cover:hover img {
    -webkit-transform: scale(1.25);
    /* Safari and Chrome */
    -moz-transform: scale(1.25);
    /* Firefox */
    -ms-transform: scale(1.25);
    /* IE 9 */
    -o-transform: scale(1.25);
    /* Opera */
    transform: scale(1.25);
}

.inside-album .photo .video-embeded,
.inside-album .video .video-embeded {
    position: relative;
    max-width: 360px;
}

.inside-album .photo .video-embeded iframe,
.inside-album .video .video-embeded iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    border: none !important;
    z-index: 1000 !important;
}

/*--------------------------------------------------------------
14.0 - Footer
--------------------------------------------------------------*/
.footer {
    position: relative;
    background: #2c2c2c;
    -webkit-backface-visibility: hidden;
    padding-bottom: 40px;
}

.footer .contact {
    margin-top: 40px;
}

.footer .contact h5 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 17px;
    color: #ec3838;
    margin-top: 0;
    margin-bottom: 20px;
}

.footer .contact p {
    font-family: 'Lato';
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
    line-height: 18px;
    font-size: 13px;
}

.footer .contact ul.adress {
    padding: 0;
    margin: 0;
    list-style: none;
    list-style-type: none;
}

.footer .contact ul.adress li {
    margin-top: 25px;
    border-bottom: 1px solid #525252;
    font-family: 'Lato';
    font-weight: 400;
    color: #9c9c9c;
    font-size: 13px;
    line-height: 20px;
}

.footer .contact ul.adress li span.heading {
    font-family: 'Lato';
    font-weight: 400;
    color: #fff;
    margin-bottom: 5px;
}

.footer .contact ul.adress li:hover {
    color: #ec3838;
}

.footer .contact .social-media ul {
    padding: 0;
    margin: 0;
    margin-top: 10px;
    list-style: none;
    list-style-type: none;
}

.footer .contact .social-media ul li {
    display: inline-block;
    margin: 5px 5px 0 0;
}

.footer .contact .social-media ul li a {
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ec3838;
    outline: none;
    text-decoration: none;
}

.footer .contact .social-media ul li a i {
    display: block;
    position: absolute;
    line-height: 20px;
    font-size: 10px;
    color: #2c2c2c;
    height: 20px;
    width: 20px;
    text-align: center;
    left: 0;
    top: 0;
}

.footer .latest-works {
    margin-top: 40px;
}

@media only screen and (min-width: 992px) {
    .footer .latest-works {
        padding-left: 80px;
    }
}

.footer .latest-works h5 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 17px;
    color: #ec3838;
    margin-top: 0;
    margin-bottom: 20px;
}

.footer .latest-works ul {
    list-style: none;
    list-style-type: none;
    padding: 0;
}

.footer .latest-works ul li {
    margin-top: 23px;
}

.footer .latest-works ul li a {
    text-decoration: none;
}

.footer .latest-works ul li a i {
    display: block;
    width: 28px;
    height: 25px;
    background: #ec3838;
    font-size: 13px;
    line-height: 25px;
    color: #fff;
    text-align: center;
    margin-right: 10px;
    float: left;
}

.footer .latest-works ul li a span.title {
    display: block;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: -3px;
}

.footer .latest-works ul li a span.date {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #9c9c9c;
}

.footer .latest-works ul li a:hover i {
    background: #eb2a2a;
}

.footer .tag-cloud {
    margin-top: 40px;
}

@media only screen and (min-width: 992px) {
    .footer .tag-cloud {
        padding-right: 50px;
    }
}

.footer .tag-cloud h5 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 17px;
    color: #ec3838;
    margin-top: 0;
    margin-bottom: 20px;
}

.footer .tag-cloud p {
    font-family: 'Lato';
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
    line-height: 18px;
    font-size: 13px;
}

.footer .tag-cloud ul {
    padding: 0;
    margin: 0;
    margin-top: 20px;
    list-style: none;
    list-style-type: none;
}

.footer .tag-cloud ul li {
    float: left;
}

.footer .tag-cloud ul li a {
    text-transform: uppercase;
    display: block;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.9);
    background: #242424;
    padding: 5px;
    margin: 0 3px 3px 0;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.footer .tag-cloud ul li a:hover {
    background: #ec3838;
}

.footer .about-footer {
    margin-top: 40px;
}

.footer .about-footer .about-footer-container h5 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 17px;
    color: #ec3838;
    margin-top: 0;
    margin-bottom: 20px;
}

.footer .about-footer .about-footer-container p {
    font-family: 'Lato';
    font-weight: 400;
    line-height: 20px;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.9);
    margin-top: 20px;
}

.footer .about-footer .about-footer-container p span.highlight {
    color: rgba(255, 255, 255, 0.5);
}

.footer .about-footer .about-footer-container img {
    margin-top: 10px;
    padding-left: 10px;
}

@media only screen and (min-width: 768px) {
    .footer .about-footer .about-footer-container img {
        float: right;
    }
}

.footer .about-footer .about-footer-container .services p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    color: #9c9c9c;
    margin-top: 15px;
}

.footer .about-footer .about-footer-container .services p a {
    text-decoration: none;
    color: #ec3838;
}

.footer .about-footer .about-footer-container .services p a:hover {
    color: #fff;
}

/*--------------------------------------------------------------
15.0 - Carousel Arrows
--------------------------------------------------------------*/
.carousel-control {
    width: 100px;
    text-shadow: none;
    filter: alpha(opacity=100);
    opacity: 1;
    background-image: none !important;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false) !important;
}

.carousel-control.left span {
    left: 0;
    margin-left: 0;
}

.carousel-control.right span {
    right: 0;
    margin-right: 0;
}

.carousel-control:hover {
    filter: alpha(opacity=100);
    opacity: 1;
}

.carousel-control:hover span {
    background: none;
    color: #2c2c2c;
}

.carousel-control .glyphicon {
    width: 25px;
    height: 50px;
    font-size: 15px;
    line-height: 50px;
    color: #fff;
    background: none;
    margin-top: -25px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

/*--------------------------------------------------------------
16.0 - Boxed - Uncomment background only for boxed version !!!
--------------------------------------------------------------*/
/* html, body {
	background: url(../img/elements/binding_dark.png) 0 0 repeat;
} */
.boxed {
    background: #242424;
    -moz-box-shadow: 0 0 0 15px #242424;
    -webkit-box-shadow: 0 0 0 15px #242424;
    box-shadow: 0 0 0 15px #242424;
}

.boxed .navbar {
    margin-left: -15px;
    padding-left: 15px;
    margin-right: -15px;
    padding-right: 15px;
}

.boxed .footer {
    -moz-box-shadow: 0 0 0 15px #2c2c2c;
    -webkit-box-shadow: 0 0 0 15px #2c2c2c;
    box-shadow: 0 0 0 15px #2c2c2c;
}

@media screen and (min-width: 768px) {
    .boxed .tp-banner-container {
        margin-left: -30px;
        margin-right: -30px;
    }

    .boxed .player-row {
        margin-left: -30px;
        margin-right: -30px;
    }

    .boxed .player-row .player {
        padding: 0 15px;
    }

    .boxed .player-row .player .jp-gui {
        padding-left: 15px;
    }

    .boxed .player-row .player .jp-playlist ul {
        left: 30px;
    }

    .boxed .tweet {
        margin-left: -30px;
        margin-right: -30px;
    }

    .boxed .tweet .hover-layer {
        padding-left: 30px;
        padding-right: 30px;
    }

    .boxed .latest-blog {
        margin-left: -30px;
        margin-right: -30px;
        padding-left: 30px;
    }
}

@media screen and (max-width: 767px) {
    .boxed .tp-banner-container {
        margin-left: -15px;
        margin-right: -15px;
    }

    .boxed .player-row {
        margin-left: -15px;
        margin-right: -15px;
    }

    .boxed .player-row .player {
        padding: 0;
    }

    .boxed .player-row .player .jp-gui {
        padding-left: 15px;
    }

    .boxed .tweet {
        margin-left: -15px;
        margin-right: -15px;
    }

    .boxed .latest-blog {
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
    }
}

/*--------------------------------------------------------------
17.0 - Shop
--------------------------------------------------------------*/
.shop.shop-home .products {
    margin: 0 -7px;
}

.shop.shop-home .products .product {
    display: block;
    float: left;
    padding: 0 7px 25px;
    width: 33.333333%;
    max-width: 360px;
}

@media screen and (max-width: 991px) {
    .shop.shop-home .products .product {
        width: 50%;
    }
}

@media screen and (max-width: 500px) {
    .shop.shop-home .products .product {
        width: 100%;
        max-width: 360px;
    }
}

.shop.shop-home .products .product .product-info {
    text-decoration: none;
    display: block;
    width: 100%;
    min-height: 16px;
    margin-top: 10px;
}

.shop.shop-home .products .product .product-info:hover .name {
    color: #ec3838;
}

.shop.shop-home .products .product .product-info .name {
    display: block;
    float: left;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #fff;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.shop.shop-home .products .product .product-info .price {
    display: block;
    float: right;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ec3838;
}

.shop.shop-home .products .product .product-info .price del {
    color: #a1a1a1;
}

@media screen and (max-width: 767px) {
    .shop.shop-home .products {
        margin: 0 -15px;
    }

    .shop.shop-home .products .product {
        padding: 0 15px 25px;
    }
}

.shop.shop-product .section {
    display: block;
    width: 100%;
    clear: both;
}

.shop.shop-product .section > img {
    display: block;
    float: left;
    width: 45%;
    height: auto;
}

.shop.shop-product .section .info-1 {
    display: block;
    float: left;
    width: 55%;
    padding-left: 30px;
    clear: right;
}

.shop.shop-product .section .info-1 form {
    margin-top: 25px;
    position: relative;
    display: block;
    min-height: 40px;
}

.shop.shop-product .section .info-1 form .numbers-row input[type=text] {
    float: left;
    width: 40px;
    height: 40px;
    margin-right: 30px;
    text-align: center;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 15px;
    line-height: 38px;
    color: #fff;
    background: #2c2c2c;
    border: 1px solid #464646;
}

.shop.shop-product .section .info-1 form .numbers-row .button {
    display: block;
    position: absolute;
    cursor: pointer;
    width: 20px;
    height: 20px;
    text-align: center;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    left: 40px;
    top: 0;
    background: #2c2c2c;
    border: 1px solid #464646;
    border-left: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.shop.shop-product .section .info-1 form .numbers-row .button.dec {
    top: 20px;
    border-top: none;
}
.shop.shop-product .section .info-1 form .buttons input[type=submit] {
    display: block;
    border: none;
    background: #ec3838;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 13px;
    line-height: 40px;
    color: #fff;
    text-transform: uppercase;
    width: 170px;
    text-align: center;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.shop.shop-product .section .info-1 form .buttons input[type=submit]:hover {
    background: #464646;
}
.shop.shop-product .section .info-1 h5 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #fff;
    margin: 0;
}
.shop.shop-product .section .info-1 span.price {
    display: block;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ec3838;
    margin-top: 15px;
}
.shop.shop-product .section .info-1 span.price del {
    color: #a1a1a1;
}
.shop.shop-product .section .info-1 span.line {
    display: block;
    width: 100%;
    height: 1px;
    background: #464646;
    margin-top: 20px;
}
.shop.shop-product .section .info-1 p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    margin: 25px 0 0;
}
.shop.shop-product .section .info-2 {
    padding-top: 30px;
}
.shop.shop-product .section .info-2 h5 {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #ec3838;
    margin: 0;
}
.shop.shop-product .section .info-2 span.line {
    display: block;
    width: 100%;
    height: 1px;
    background: #464646;
    margin-top: 20px;
}
.shop.shop-product .section .info-2 p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #fff;
    margin: 25px 0 0;
}
.shop.shop-product .section .info-2 ul.details {
    display: table;
    float: left;
    margin: 17px 0 0;
    padding: 0;
    width: 30%;
    margin-right: 30px;
    margin-bottom: 10px;
    list-style: none;
    list-style-type: none;
}
@media screen and (max-width: 991px) {
    .shop.shop-product .section .info-2 ul.details {
        width: 100%;
        float: none;
    }
}
.shop.shop-product .section .info-2 ul.details li {
    display: block;
    width: 100%;
    border-bottom: 1px solid #464646;
    margin-top: 5px;
    padding: 8px 0;
}
.shop.shop-product .section .info-2 ul.details li span {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    text-transform: uppercase;
}
.shop.shop-product .section .info-2 ul.details li span.colored {
    color: #ec3838;
}
.shop.shop-product .section .info-2 ul.details li:first-child {
    margin-top: 0;
}
@media screen and (max-width: 991px) {
    .shop.shop-product .section > img {
        float: none;
        width: auto;
    }
    .shop.shop-product .section .info-1 {
        float: none;
        width: 100%;
        padding-left: 0;
        margin-top: 30px;
    }
}
.shop.shop-product .section .simillar-products {
    margin: 50px -7px 0;
}
.shop.shop-product .section .simillar-products .product {
    display: block;
    float: left;
    padding: 0 7px 25px;
    width: 33.333333%;
    max-width: 360px;
}
@media screen and (max-width: 500px) {
    .shop.shop-product .section .simillar-products .product {
        width: 100%;
        max-width: 360px;
    }
}
.shop.shop-product .section .simillar-products .product .product-info {
    text-decoration: none;
    display: block;
    width: 100%;
    min-height: 16px;
    margin-top: 10px;
}
.shop.shop-product .section .simillar-products .product .product-info:hover .name {
    color: #ec3838;
}
.shop.shop-product .section .simillar-products .product .product-info .name {
    display: block;
    float: left;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #fff;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.shop.shop-product .section .simillar-products .product .product-info .price {
    display: block;
    float: right;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ec3838;
}
.shop.shop-product .section .simillar-products .product .product-info .price del {
    color: #a1a1a1;
}
@media screen and (max-width: 991px) and (min-width: 501px) {
    .shop.shop-product .section .simillar-products .product .product-info .price,
    .shop.shop-product .section .simillar-products .product .product-info .name {
        float: none;
    }
}
@media screen and (max-width: 767px) {
    .shop.shop-product .section .simillar-products {
        margin: 50px -15px 0;
    }
    .shop.shop-product .section .simillar-products .product {
        padding: 0 15px 25px;
    }
}
.shop .sidebar-1 .featured-posts ul li {
    display: block;
    clear: both;
    padding: 20px 0;
    min-height: 88px;
}
.shop .sidebar-1 .featured-posts ul li a {
    display: block;
    text-decoration: none;
    outline: none;
}
.shop .sidebar-1 .featured-posts ul li a:hover span.title {
    color: #ec3838;
}
.shop .sidebar-1 .featured-posts ul li a span.title {
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.shop .sidebar-1 .featured-posts ul li a span.price {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #a4a4a4;
}
.shop .sidebar-1 .featured-posts ul li a span.price i {
    color: #ec3838;
}
.shop .sidebar-1 .featured-posts ul li a span.price i:before,
.shop .sidebar-1 .featured-posts ul li a span.price i:after {
    margin: 0;
}
.shop .sidebar-1 .filter {
    display: block;
    width: 100%;
}
.shop .sidebar-1 .filter .heading {
    margin-bottom: 20px;
}
.shop .sidebar-1 .filter .filter-btn {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    padding: 7px 11px;
    color: #989fa5;
    background: #242424;
    border: none;
    margin-top: 15px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.shop .sidebar-1 .filter .filter-btn:hover {
    color: #7d868d;
}
.nav-active a.nav-item{
    color: #ec3838 !important;
}

.cover img {
    height: 220px !important;
    width: 220px !important;
}
.cover.mixtapeDetail img {
    min-width: auto !important;
    max-width: 100% !important;    
}