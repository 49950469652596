/* Ion.RangeSlider
// css version 1.8.1
// by Denis Ineshin | ionden.com
// ===================================================================================================================*/

/* =====================================================================================================================
// RangeSlider */

.irs {
    position: relative; display: block;
}
    .irs-line {
        position: relative; display: block;
        overflow: hidden;
    }
        .irs-line-left, .irs-line-mid, .irs-line-right {
            position: absolute; display: block;
            top: 0;
        }
        .irs-line-left {
            left: 0; width: 10%;
        }
        .irs-line-mid {
            left: 10%; width: 80%;
        }
        .irs-line-right {
            right: 0; width: 10%;
        }

    .irs-diapason {
        position: absolute; display: block;
        left: 0; width: 100%;
    }
    .irs-slider {
        position: absolute; display: block;
        cursor: default;
        z-index: 1;
    }
        .irs-slider.single {
            left: 10px;
        }
            .irs-slider.single:before {
                position: absolute; display: block; content: "";
                top: -50%; left: -100%;
                width: 300%; height: 200%;
                background: rgba(0,0,0,0.0);
            }
        .irs-slider.from {
            left: 100px;
        }
            .irs-slider.from:before {
                position: absolute; display: block; content: "";
                top: -50%; left: -200%;
                width: 300%; height: 200%;
                background: rgba(0,0,0,0.0);
            }
        .irs-slider.to {
            left: 300px;
        }
            .irs-slider.to:before {
                position: absolute; display: block; content: "";
                top: -50%; left: 0;
                width: 300%; height: 200%;
                background: rgba(0,0,0,0.0);
            }
        .irs-slider.last {
            z-index: 2;
        }

    .irs-min {
        position: absolute; display: block;
        left: 0;
        cursor: default;
    }
    .irs-max {
        position: absolute; display: block;
        right: 0;
        cursor: default;
    }

    .irs-from, .irs-to, .irs-single {
        position: absolute; display: block;
        top: 0; left: 0;
        cursor: default;
        white-space: nowrap;
    }


.irs-grid {
    position: absolute; display: none;
    bottom: 0; left: 0;
    width: 100%; height: 20px;
}
.irs-with-grid .irs-grid {
    display: block;
}
    .irs-grid-pol {
        position: absolute;
        top: 0; left: 0;
        width: 1px; height: 8px;
        background: #000;
    }
    .irs-grid-pol.small {
        height: 4px;
    }
    .irs-grid-text {
        position: absolute;
        bottom: 0; left: 0;
        width: 100px;
        white-space: nowrap;
        text-align: center;
        font-size: 9px; line-height: 9px;
        color: #000;
    }